import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
import bg from 'components/asset/images/backgroundImg.svg';
import { GButton } from 'components/atom/button';
import file from 'components/asset/images/documentFile.svg';
import {
  authLogin,
  sendMailAuthCode,
  sendMailConfirm,
  validVenderId,
  validJointCertificate,
  authLoginDev,
  getVendorIdMailApi,
} from 'apis/vars/login/Login';
import LoginInput from 'components/vars/common/LoginInput';
import PassWordInputVars from 'components/vars/common/PassWordInputVars';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoading } from 'components/process/Loading';
import { useCommonModal } from 'hooks/useCommonModal';
import { useCommonCert } from 'hooks/useCommonCert';

import { devLogin } from 'apis/Session';
import useLanguageStore from 'stores/useLanguageStore';
import useSessionStore from 'stores/useSessionStore';
import useAuthStore from 'stores/useAuthStore';
import useLogin from './hooks/useLogin';
import { MenuContext } from 'App';
import { ValidationBox } from 'components/validation/ValidationBox';
import { MenuContextType } from 'models/admin/Menu';
import GSelectMUIAtom from 'components/atom/select';
import MemberResetModal from './MemberResetModal';
import { SelectChangeEvent } from '@mui/material';
import { useMessageBar } from 'components/process/MessageBar';
import PrivacyPolicyModal from 'components/vars/login/PrivacyPolicyModal';
import HelpDeskModal from './HelpDeskModal';
import GuideManualModal from './GuideManualModal';

interface Props {
  gap?: string;
}

export interface LoginFormData {
  userId: string;
  password?: string;
  publCertKey?: string;
  emlAddr?: string;
  langCd: string;
  emlAuthCd?: string;
  signUpYn?: string;
  sapValidYn?: string;
  emlCertYn?: string;
  dgsVal?: string;
  bzno?: string;
  bznoKey?: string;
  vdcpCtryCd?: string;
}

export interface AdminLoginFormData {
  userId: string;
  langCd: string;
}

const defaultValuesAdminLoginForm: AdminLoginFormData = {
  userId: 'admin',
  // userId: 'brotherjun',
  langCd: 'ko',
};

const langType = ['ko', 'en', 'zhC'];

const LoginPage = () => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const navigate = useNavigate();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();

  const { setAccessCode } = useAuthStore();
  const { changeLanguage } = useLanguageStore();
  const { setSession, langCd } = useSessionStore();

  const { vendorIdSchema, loginSchema } = useLogin();
  const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
  const [loginFormData, setLoginFormData] = useState<LoginFormData>({
    userId: '',
    password: '',
    publCertKey: '',
    emlAddr: '',
    langCd: langCd,
    emlCertYn: 'N',
    dgsVal: '',
    bzno: '',
    bznoKey: '',
    vdcpCtryCd: '',
  });
  const [validationErrMap, setValidationErrMap] = useState<Map<string, string>>(new Map());
  const [resetMemberModalOpen, setResetMemberModalOpen] = useState<boolean>(false);
  const [policyModalOpen, setPolicyModalOpen] = useState<boolean>(false);
  const [helpDeskModalOpen, setHelpDeskModalOpen] = useState<boolean>(false);
  const [guideManualModalOpen, setGuideManualModalOpen] = useState<boolean>(false);

  const [validVenderIdFlag, setValidVanderIdFlag] = useState<boolean>(false);
  const [sendEmlYn, setSendEmlYn] = useState<boolean>(false);
  const [pwFlag, setPwFlag] = useState<boolean>(false);
  const [authEmlYn, setAuthEmlYn] = useState<boolean>(false);
  const menuContext = useContext<MenuContextType>(MenuContext);
  const [adminLoginFormData, setBasicFormData] = useState<AdminLoginFormData>(
    defaultValuesAdminLoginForm
  );
  const { signedText, rValueBox, callCertModule } = useCommonCert();
  const [option, setOption] = useState('2');

  // 인증번호 3분 타이머 설정
  const initialTime = 600;
  const [time, setTime] = useState<number>(initialTime);
  const [timerOn, setTimerOn] = useState<boolean>(false);
  const [showCertNum, setShowCertNum] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
    changeLanguage(langType[parseInt(event.target.value) - 1 + '']);
    setLoginFormData((prev) => {
      return { ...prev, langCd: langType[parseInt(event.target.value) - 1 + ''] };
    });
  };

  /**
   * 회원가입
   */
  const handleClickSignUp = () => {
    navigate('/member/regist');
  };

  /**
   * 관리자 임시 로그인
   * @param e
   */
  const handleClickAdminLogin = async (e) => {
    const response = await devLogin(adminLoginFormData.userId, adminLoginFormData.langCd);
    const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
    if (response.successOrNot === 'Y' && response?.data) {
      const session = response.data;
      changeLanguage(session.langCd || 'en');
      setSession(session);
      setAccessCode('INTERNAL'); //임시 하드코딩
      // navigate('/', { replace: true });
      navigate('/system/admin/member/member-list', { replace: true });
    } else {
      openCommonModal({ content: t('com.label.00715', 'Login Fail') });
    }
  };

  /**
   * 공동인증 로그인
   */
  const certLogin = () => {
    validJointCertificate(loginFormData).then((resp) => {
      if (resp) {
        vendorLogin();
      } else {
        openCommonModal({
          content: t('com.label.00716', '유효하지 않은 인증서입니다.'),
        });
      }
    });
  };

  /**
   * 공동인증 로컬 테스트
   */
  const handleClickCert = () => {
    console.log('loginFormData.bznoKey', loginFormData);
    loginFormData.bznoKey = '2234567890123';
    callCertModule({
      userId: loginFormData.userId,
      bznoKey: loginFormData.bznoKey,
      callbackFn: (resultObject) => {
        setLoginFormData((prev) => {
          return { ...prev, publCertKey: resultObject.RValue, dgsVal: resultObject.signedData };
        });
      },
    });
  };

  /**
   * 로그인 처리
   */
  const vendorLogin = async () => {
    const response = await authLogin(loginFormData);
    if (response.successOrNot === 'Y' && response?.data) {
      const session = response.data;
      changeLanguage(session.langCd || 'en');
      setSession(session);
      menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '000139' });
      navigate('/account-regist', { replace: true });
    } else {
      openCommonModal({ content: t('com.label.00715', '__Login Fail') + ' : ' + response?.data });
    }
  };

  /**
   * 로그인 클릭 이벤트
   * @param e
   */
  const handleClickLogin = async (e) => {
    try {
      await checkValidate(loginSchema, loginFormData);
      await vendorLogin();
    } catch (e: any) {
      return;
    }
  };

  /**
   * 개발전용 로그인
   */
  const handleClickDevLogin = async () => {
    // TEST CN002809A KR000782(C100)
    // 지급조회 KR017940
    // OV USP00028
    // test : US000269, KR34416600
    // const response = await authLoginDev({ userId: 'KR34416600', langCd: langCd });
    // const response = await authLoginDev({ userId: 'US000269', langCd: langCd });
    const response = await authLoginDev({ userId: 'US001725', langCd: langCd });
    // const response = await authLoginDev({ userId: 'US000795', langCd: langCd });
    // const response = await authLoginDev({ userId: 'CN002809A', langCd: langCd });
    // const response = await authLoginDev({ userId: 'CN009856', langCd: langCd });
    // const response = await authLoginDev({ userId: 'CN000274', langCd: langCd });
    // const response = await authLoginDev({ userId: 'CN000223', langCd: langCd });
    // const response = await authLoginDev({ userId: 'US003510', langCd: langCd });
    // const response = await authLoginDev({ userId: 'US002160', langCd: langCd });

    if (response.successOrNot === 'Y' && response?.data) {
      const session = response.data;
      changeLanguage(session.langCd || 'en');
      setSession(session);
      setAccessCode('INTERNAL'); //TODO 임시 하드코딩

      menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '000139' });

      navigate('/account-regist', { replace: true });
    }
  };

  /**
   * 패스워드 체인지
   */
  const handleClickMemberReset = () => {
    setResetMemberModalOpen(true);
  };

  // 개인정보 처리방침
  const handleClickPrivacy = () => {
    setPolicyModalOpen(true);
  };
  /**
   * 벤더 id 체크
   */
  const handleClickVenderIdCheck = async () => {
    try {
      await checkValidate(vendorIdSchema, loginFormData);
      if (!loginFormData.userId) {
        return;
      }
      // bznoKey 생성
      const response = await validVenderId(loginFormData.userId);
      if (response.sapValidYn == 'Y') {
        setValidVanderIdFlag(true);
        setLoginFormData({
          ...loginFormData,
          bznoKey: response.bznoKey,
          vdcpCtryCd: response.vdcpCtryCd,
        });

        const respSignUpYn = response.signUpYn; //승인여부
        const respDmstFrgDivsCd = response.dmstFrgDivsCd; //해외여부 DOM || OVS
        const bznoKey = response.bznoKey;
        const vdcpCtryCd = response.vdcpCtryCd;

        if (respSignUpYn == 'N') {
          openCommonModal({
            modalType: 'confirm',
            content: t('com.label.00035', '__회원가입이 필요합니다 가입페이지로 이동하시겠습니까?'),
            yesCallback() {
              navigate('/member/regist');
            },
            noCallback() {
              return;
            },
          });
          return;
        } else if (respSignUpYn == 'R') {
          openCommonModal({
            modalType: 'alert',
            content: t(
              'com.label.00498',
              '회원가입승인 처리중입니다. 승인 처리시 이메일이 발송됩니다.'
            ),
          });
          return;
        } else {
          if (vdcpCtryCd && vdcpCtryCd.toUpperCase().startsWith('KR')) {
            // 국내기준 변경(vendorId가 'kr'로 시작하는 경우) > 공동인증서 로그인만 진행
            // 0418 공동인증 식별 기준 변경 > ERP Resp의 Land1이 'KR'인 경우
            callCertModule({
              userId: loginFormData.userId,
              bznoKey: bznoKey,
              callbackFn: (resultObject) => {
                setLoginFormData((prev) => {
                  return {
                    ...prev,
                    publCertKey: resultObject.RValue,
                    dgsVal: resultObject.signedData,
                  };
                });
              },
            });
          } else {
            // 해외의 경우 메일 인증
            // TODO : 이메일 명시
            openLoading(true);
            getVendorIdMailApi(loginFormData.userId).then((mail) => {
              sendMailAuthCode(loginFormData.userId).then((resp) => {
                if (resp.successOrNot == 'Y') {
                  openCommonModal({
                    content: (
                      <>
                        {t('com.label.00037', '__가입시 입력한 이메일로 인증번호를 보냈습니다.')}
                        <br />
                        {mail}
                      </>
                    ),
                  });
                  startTimer();
                  setSendEmlYn(true);
                  setShowCertNum(true);
                  openLoading(false);
                } else {
                  openLoading(false);
                }
              });
            });
          }
        }
      } else {
        openCommonModal({
          modalType: 'alert',
          content: t('com.label.00407', '__잘못된 아이디 입니다.'),
        });
        return;
      }
    } catch (e: any) {
      openLoading(false);
      return;
    }
  };

  // 해외vendorID 로그인 시 인증번호 3분 타이머 설정
  useEffect(() => {
    let interval;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(interval);
            openCommonModal({
              modalType: 'alert',
              content: t(
                'com.label.00661',
                '__인증번호가 만료되었습니다. 다시 요청해 주시기 바랍니다.'
              ),
            });
            // 3분 만료시 인증번호 입력창 닫힘
            setShowCertNum(false);
            return 0;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerOn, showCertNum]);

  const startTimer = () => {
    setTime(initialTime);
    setTimerOn(true);
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainSec = seconds % 60;
    return `${minutes < 10 ? '0' + minutes : minutes}:${
      remainSec < 10 ? '0' + remainSec : remainSec
    }`;
  };

  /**
   * 이메일 확인
   */
  const handleClickEmailConfirm = async () => {
    await checkValidate(vendorIdSchema, loginFormData);
    const response = await sendMailConfirm(loginFormData);
    if (response.successOrNot === 'Y') {
      openMessageBar('success', t('com.label.00040', '__인증에 성공하였습니다.'));
      setTimerOn(false);
      setAuthEmlYn(true);
      setLoginFormData((prev) => {
        return { ...prev, emlCertYn: 'Y' };
      });
      setPwFlag(true);
    } else {
      openMessageBar('warning', t('com.label.00408', '__입력한 내용이 올바르지 않습니다'));
    }
  };

  /**
   * validation
   * @param object
   */
  const checkValidate = async (schema: any, targetData: any) => {
    try {
      setValidationErrMap(new Map());
      await schema.validate(targetData, { abortEarly: false });
    } catch (e: any) {
      const errMap = new Map();
      e.inner?.map((err) => {
        errMap.set(err.path, err.message);
      });
      setValidationErrMap(errMap);
      if (errMap) {
        return;
      }
    }
  };

  /**
   * 안내 매뉴얼
   * @returns
   */
  const handleClickManual = () => {
    setGuideManualModalOpen(true);
    {
      /* 
    openCommonModal({
      modalType: 'alert',
      content: t('개발예정입니다.', '__개발예정입니다.'),
    });
    return;
    */
    }
  };

  /**
   * 인풋 컴포넌트 체인지 이벤트
   * @param e
   */
  const handleChangeForLoginForm = (e) => {
    if (e.target.id === 'userId') {
      e.target.value = e.target.value.trim().toUpperCase();
    }
    if (e.target.id === 'emlAuthCd') {
      e.target.value = e.target.value.replace(/[^\d]/g, '', '');
    }

    setLoginFormData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  /**
   * Init
   */
  useEffect(() => {
    console.log('init');
    setLoginFormData((prev) => {
      return {
        ...prev,
        langCd: langType[parseInt(option) - 1 + ''],
      };
    });
  }, []);

  /**
   * 공동인증 로그인 처리
   */
  useEffect(() => {
    if (loginFormData.publCertKey) {
      certLogin();
    }
  }, [loginFormData.publCertKey]);

  return (
    <Wrap>
      <Container>
        <TextBox>
          <p>
            지급계좌등록시스템
            <br />
          </p>
          <EngBox>
            <p>Vendor Account Registration System</p>
          </EngBox>
          <p>支付账户注册系统</p>
          <br />
          <img src="/assets/icon/logo_en_v2.svg" alt="로고" />
        </TextBox>
        <LoginBox>
          <LogoWrap>
            <Title>VARS</Title>
            <SubTitleWrap>
              {langCd === 'ko' || !langCd ? (
                <>
                  <KoSubTitle>{t('지급계좌등록시스템', '지급계좌등록시스템')}</KoSubTitle>
                  <EnSubTitle>Vendor Account Registration System</EnSubTitle>
                </>
              ) : (
                <>
                  <KoSubTitle>Vendor Account Registration System</KoSubTitle>
                  <EnSubTitle>{t('지급계좌등록시스템', '지급계좌등록시스템')}</EnSubTitle>
                </>
              )}
            </SubTitleWrap>
          </LogoWrap>
          <FlexBox gap="24px">
            <FlexBox gap="16px">
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {appEnv == 'local' && (
                  <>
                    <GButton
                      txt="공인인증 테스트 (로컬전용)"
                      sizes="medium"
                      btnstyled="contained"
                      btnColor="normal"
                      hasImg={false}
                      hasTxt={true}
                      onClick={handleClickCert}
                    ></GButton>

                    <GButton
                      txt="관리자 로그인 (개발용)"
                      sizes="medium"
                      btnstyled="contained"
                      btnColor="normal"
                      hasImg={false}
                      hasTxt={true}
                      onClick={handleClickAdminLogin}
                    ></GButton>
                  </>
                )}
                <ValidationBox msg={validationErrMap.get('userId')}>
                  <LoginInput
                    id="userId"
                    type="text"
                    label={t2('com.label.vendorcdid', '__Vendor Code ID')}
                    onChange={handleChangeForLoginForm}
                  >
                    <GButton
                      txt={t('com.label.00146', '확인')}
                      sizes="medium"
                      btnstyled="outline"
                      btnColor="normal"
                      hasImg={false}
                      hasTxt={true}
                      style={{ marginTop: '10px', marginLeft: '10px' }}
                      onClick={handleClickVenderIdCheck}
                    />
                  </LoginInput>
                </ValidationBox>
                {sendEmlYn && showCertNum && (
                  <ValidationBox msg={validationErrMap.get('emlAuthCd')}>
                    <LoginInput
                      id="emlAuthCd"
                      type="text"
                      label={t2('com.label.00501', '인증번호')}
                      onChange={handleChangeForLoginForm}
                    >
                      <ProoveNum>
                        <span
                          style={{
                            marginTop: '20px',
                            marginRight: '4px',
                            color: '#979998',
                            fontFamily: 'Spoqa Han Sans Neo',
                            fontSize: '13px',
                          }}
                        >
                          {formatTime(time)}
                        </span>
                        <GButton
                          txt={t('com.label.00039', '인증')}
                          sizes="medium"
                          btnstyled="outline"
                          btnColor="normal"
                          hasImg={false}
                          hasTxt={true}
                          style={{ marginTop: '10px' }}
                          disabled={!validVenderIdFlag || !sendEmlYn}
                          onClick={handleClickEmailConfirm}
                        />
                      </ProoveNum>
                    </LoginInput>
                  </ValidationBox>
                )}
                {authEmlYn && (
                  <PassWordInputVars
                    id="password"
                    type="password"
                    label="password"
                    disabled={!authEmlYn}
                    onChange={handleChangeForLoginForm}
                  />
                )}
              </div>
            </FlexBox>
            <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
              <GSelectMUIAtom
                selectWidth="100%"
                selectHeight="48px"
                value={option}
                handleChange={handleChange}
                option={['KOR', 'ENG', 'CHN']}
              />
            </div>

            <FlexBox gap="8px">
              <GButton
                txt={t('com.label.00003', '__로그인')}
                sizes="login"
                btnstyled="contained"
                btnColor="normal"
                hasImg={false}
                hasTxt={true}
                disabled={!authEmlYn}
                style={{ height: '54px' }}
                onClick={handleClickLogin}
              />
              {appEnv == 'local' && (
                <GButton
                  txt={`${t('com.label.00003', '로그인')}(로컬전용)`}
                  sizes="login"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={false}
                  hasTxt={true}
                  style={{ height: '54px' }}
                  onClick={handleClickDevLogin}
                />
              )}
              <Linked>
                <a
                  href="javascript:void(0)"
                  style={{ color: '#1F1F1F', height: '28px' }}
                  onClick={handleClickMemberReset}
                >
                  {t('com.label.00401', '__회원 정보 초기화')}
                </a>
                <a
                  href="javascript:void(0)"
                  style={{ color: '#5B5C5B', fontWeight: '700', height: '28px' }}
                  onClick={handleClickSignUp}
                >
                  {t('com.label.00005', '회원가입')}
                </a>
              </Linked>
            </FlexBox>
            <div
              style={{
                borderTop: '1px solid #DDE0DF',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            ></div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Button onClick={handleClickManual} style={{ gap: '8px' }}>
                <ButtonText>
                  <img src={file} alt="Document File" />
                  <span>{t('com.label.00006', '__안내매뉴얼')}</span>
                </ButtonText>
              </Button>
              <Linked style={{ padding: '0px' }}>
                <a
                  href="javascript:void(0)"
                  onClick={handleClickPrivacy}
                  style={{ color: '#979998' }}
                >
                  {t('com.label.00007', '__개인정보처리방침')}
                </a>
                <a
                  href="javascript:void(0)"
                  style={{ color: '#979998' }}
                  onClick={() => {
                    setHelpDeskModalOpen(true);
                  }}
                >
                  {t('com.label.helpdesk', '__Help Desk')}
                </a>
                {/* <a
                  href="https://avendor.lgensol.com:448/jsp/glaw/contract_search.jsp"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#979998' }}
                >
                  법무 계약서 확인
                </a> */}
              </Linked>
            </div>
          </FlexBox>
        </LoginBox>
      </Container>
      {resetMemberModalOpen && (
        <MemberResetModal
          open={resetMemberModalOpen}
          close={() => setResetMemberModalOpen(false)}
          save={() => console.log()}
        />
      )}
      {policyModalOpen && (
        <PrivacyPolicyModal
          open={policyModalOpen}
          close={() => setPolicyModalOpen(false)}
          save={() => console.log()}
        />
      )}
      {helpDeskModalOpen && (
        <HelpDeskModal
          open={helpDeskModalOpen}
          close={() => setHelpDeskModalOpen(false)}
          save={() => console.log()}
        />
      )}
      {guideManualModalOpen && (
        <GuideManualModal
          open={guideManualModalOpen}
          close={() => setGuideManualModalOpen(false)}
          save={() => console.log()}
        />
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-size: 488px 388px;
  background: ${GlobalTokenColor.ESGrey100} url(${bg}) no-repeat center center;
  justify-content: center;
  min-height: 60vh;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 300px;
  align-items: center;
`;

const TextBox = styled.div`
  width: 580px;
  height: 300px;
  margin-left: 280px;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    font-family: Spoqa Han Sans Neo;
  }
  p:nth-of-type(1) {
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
  }
`;

const LoginBox = styled.div`
  width: 468px;
  height: auto;
  background: ${GlobalTokenColor.ESGreyWHite};
  border-radius: 2px;
  padding: 64px;
  img {
    width: 340px;
    height: 68px;
  }
  gap: 32px;
  display: flex;
  flex-direction: column;
`;

const LogoWrap = styled.div`
  width: 340px;
  height: 68px;
  padding: 16px, 0px, 16px, 0px;

  display: flex;
  align-items: center;

  color: #135678;
  // gap: 12px;
`;

const Title = styled.span`
  padding-right: 12px;
  border-right: 1px solid #b9bcbb;

  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
`;

const EngBox = styled.div`
  display: flex;
  gap: 15px;
  p:first-child {
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
  }
  p:last-child {
    font-weight: 400;
    font-size: 20px;
    line-height: 60px;
    color: #5b5c5b;
    margin-top: 6px;
  }
`;

const SubTitleWrap = styled.div`
  max-width: 300px;
  height: 33px;
  padding-left: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const KoSubTitle = styled.span`
  line-height: 18px;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
`;

const EnSubTitle = styled(KoSubTitle)`
  color: #6e706f;
  font-size: 12px;
  font-weight: 300;
`;

const Linked = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

const Button = styled.button`
  display: flex;
  width: 100%;
  height: 36px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    margin-right: 8px;
    color: #5b5c5b;
    font-family: Spoqa Han Sans Neo;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const ProoveNum = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FlexBox = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => props.gap && `gap: ${props.gap};`}
`;

export default LoginPage;
