import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'components/process/MessageBar';
import { useLoading } from 'components/process/Loading';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import ModalLayout from 'components/layouts/ModalLayout';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GLabelAtom } from 'components/atom/label';
import { GCodeSelect } from 'components/selects/GCodeSelect';
import InputField from 'components/atom/input';
import { CommonCode } from 'models/admin/CommonCode';
import styled from '@emotion/styled';
import Save from 'components/asset/images/Confirm.svg';
import FileUploadVars from 'components/vars/common/FileUploadVars';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import useFrgnRegist from './hooks/useFrgnRegist';
import { makeErrorMapFrom } from 'utils/ValidationUtil';
import ForeignCurrencyBank, { frnrSearchParam } from './FrgnCurrencyBankModal';
import { ForeignAccountVO } from 'models/vars/account/ForeignAccountVO';
import { registForeignAccount } from 'apis/vars/account/ForeignAccount';
import {
  getAccnValidG0237,
  getAccnValidInfo,
  getSapIfStdInfo,
  getSapIfStdInfoG,
} from 'apis/vars/account/CommonAccount';
import { AccountValidVO } from 'models/vars/account/AccountValidVO';
import GRadioGroup from 'components/radios/GRadioGroup';
import { AlignBox } from 'components/organism/AlignBox';
import { getAccnValid0237 } from 'apis/vars/account/CommonAccount';
import { GLabelAtomVars } from 'components/vars/common/GLabelAtomVars';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { getJwtCommonCodes } from 'apis/vars/common/Common';
import { borderBottomStyle } from 'html2canvas/dist/types/css/property-descriptors/border-style';
import Download from 'components/asset/images/Download.svg';
import usePifPrint from '../hooks/usePifPrint';

type DmsRegModalProps = {
  open: boolean;
  vendorInfo: any;
  isAccnExist: string;
  frgnDftAccount: any;
  close: (param?: boolean) => void;
  save: () => void;
  isKrw: boolean;
  accounts: any;
};

interface AccnRegVO extends ForeignAccountVO {
  currCd?: string; //미사용
  isAccnExist?: string;
  ibanYn?: string;
  isDpstNmCert?: string; // 화면에서 사용
  isDpstNmCertRslt?: string;
}

interface AccnFiles {
  fileId1?: string;
  fileId2?: string;
  fileId3?: string;
  // fileId4?: string;
  fileId5?: string;
}

const DmsRegFrgnAcntModal = (props: DmsRegModalProps) => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const [validationErrMap, setValidationErrMap] = useState<Map<string, string>>(new Map());
  const { regSchema, regSchemaKrw, regSchemaDo } = useFrgnRegist();
  const [dmstFrgDivsCd, setDmstFrgDivsCd] = useState<string>(props.vendorInfo.dmstFrgDivsCd);
  const [bankNumberModalOpen, setBankNumberModalOpen] = useState<boolean>(false);

  const [certFlag, setCertFlag] = useState<boolean>(false);

  const [wktDivsCdList, setWktDivsCdList] = useState<CommonCode[]>([]);

  const files = {} as AccnFiles;

  const fileDropzoneRef1 = useRef<any>();
  const fileDropzoneRef2 = useRef<any>();
  const fileDropzoneRef3 = useRef<any>();
  const fileDropzoneRef4 = useRef<any>();
  const fileDropzoneRef5 = useRef<any>();

  const [regAccnInfo, setRegAccnInfo] = useState<AccnRegVO>({
    ...props.vendorInfo,
    accnNoCertYn: 'N',
    addAtrValCtn06: 'N',
    isDpstNmCert: props.isKrw ? 'Y' : 'N',
    isAccnExist: props.isAccnExist,
    currCd: props.isKrw ? 'KRW' : 'USD',
    copList: [props.vendorInfo.soprMgrCopCd],
    ibanYn: 'Normal',
    bankCtryCd: props.isKrw ? 'KR' : '',
    txCd: props.vendorInfo.copRegNo,
    wktDivsCd: 'N',
    isDpstNmCertRslt: 'N',
    bankNoTpCd: '',
    bankNo: '',
  } as AccnRegVO);

  const [curCdList, setCurCdList] = useState<CommonCode[]>([]);
  const [atchFileTpCdList, setAtchFileTpCdList] = useState<CommonCode[]>([]); //ATCH_FILE_FCUR_CD
  const [bankNoTpCdList, setBankNoTpCdList] = useState<CommonCode[]>([]); //BANK_NO_TP_CD
  const [bankLoctCtryCdList, setBankLoctCtryCdList] = useState<CommonCode[]>([]); //BANK_LOCT_CTRY_CD

  const handleClose = (result?: boolean) => {
    props.close(result);
  };

  const emailValid = () => {
    // 이메일 형식
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emails = [regAccnInfo.bankEml];

    const invalidEmails = emails.filter((email) => email && !emailRegex.test(email));
    if (invalidEmails.length > 0) {
      openMessageBar('warning', t('com.label.00230', '__이메일 형식이 올바르지 않습니다.'));
      return false;
    }
    return true;
  };

  /**
   * 인풋 컴포넌트 체인지 이벤트
   * @param e
   */
  const handleChangeForRegAccn = (e) => {
    const specialChars = ['!', '@', '#', '$', '%', '^', '&', '*', '/', '[', ']', '{', '}'];

    if (e.target.id == 'dpstNm') {
      e.target.value = e.target.value.replace(/[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); //한글 입력 방지
      // e.target.value = e.target.value.replace(/[^\x00-\x7F\u4E00-\u9FA5]+/g, ''); //한글 입력 방지, 중문 입력가능

      const replacedString = e.target.value.replace(
        new RegExp(`[${specialChars.join('\\')}]`, 'g'),
        ''
      );
      e.target.value = replacedString;

      e.target.value = e.target.value.replace(/[a-z]/g, (match) => {
        return match.toUpperCase();
      });

      setRegAccnInfo((prev) => {
        return {
          ...prev,
          addAtrValCtn06: 'N',
          isDpstNmCertRslt: 'N',
        };
      });
    }

    // 해외 외화 계좌등록
    if (dmstFrgDivsCd == 'OV') {
      if (e.target.id == 'bankNm') {
        e.target.value = e.target.value.replace(
          /[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣!#$%^&*()=+{}[\]:;"'<>,?/\\|`~]/g,
          ''
        );
        setRegAccnInfo((prev) => {
          return {
            ...prev,
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }
      if (e.target.id == 'bankAddr') {
        e.target.value = e.target.value.replace(
          /[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣!#$%^&*()=+{}[\]:;"'<>,?/\\|`~]/g,
          ''
        );
        setRegAccnInfo((prev) => {
          return {
            ...prev,
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }

      if (e.target.id == 'bankEml') {
        // 한글과 특정 특수문자를 제거하는 정규식
        e.target.value = e.target.value.replace(
          /[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣!#$%^&*()=+{}[\]:;"'<>,?/\\|`~]/g,
          ''
        );

        setRegAccnInfo((prev) => {
          return {
            ...prev,
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }

      if (e.target.id == 'usePurpCtn') {
        e.target.value = e.target.value.replace(
          /[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣!#$%^&*()=+{}[\]:;"'<>,?/\\|`~]/g,
          ''
        );
        setRegAccnInfo((prev) => {
          return {
            ...prev,
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }
    }
    // 해외벤더로 로그인했을때 Address of Bank 한국어 못들어가도록
    // if (dmstFrgDivsCd == 'OV' && !props.isKrw) {
    //   if (e.target.id == 'bankAddr') {
    //     e.target.value = e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); //한글 입력 방지

    //     const replacedString = e.target.value.replace(
    //       new RegExp(`[${specialChars.join('\\')}]`, 'g'),
    //       ''
    //     );
    //     e.target.value = replacedString;

    //     setRegAccnInfo((prev) => {
    //       return {
    //         ...prev,
    //         addAtrValCtn06: 'N',
    //         isDpstNmCertRslt: 'N',
    //       };
    //     });
    //   }
    // }

    if (e.target.id == 'encAccnNo') {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
      e.target.value = e.target.value.replace(/[a-z]/g, (match) => {
        return match.toUpperCase();
      });
      setRegAccnInfo((prev) => {
        return {
          ...prev,
          addAtrValCtn06: 'N',
          isDpstNmCertRslt: 'N',
        };
      });
    }

    if (e.target.id == 'ibanCd') {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
      e.target.value = e.target.value.replace(/[a-z]/g, (match) => {
        return match.toUpperCase();
      });
      setRegAccnInfo((prev) => {
        return {
          ...prev,
          addAtrValCtn06: 'N',
          isDpstNmCertRslt: 'N',
        };
      });
    }

    if (e.target.id == 'swftCd') {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
      e.target.value = e.target.value.replace(/[a-z]/g, (match) => {
        return match.toUpperCase();
      });
      const tmpVal = e.target.value;
      if (tmpVal.length >= 6) {
        const tmpBankLoctCtryCd = tmpVal.substring(4, 6);
        if (bankLoctCtryCdList.some((o) => o.cmnCd == tmpBankLoctCtryCd)) {
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              bankLoctCtryCd: tmpBankLoctCtryCd,
              bankCtryCd: tmpBankLoctCtryCd,
              addAtrValCtn06: 'N',
              isDpstNmCertRslt: 'N',
            };
          });
        }
      }
    }

    if (e.target.id == 'bankCtpl') {
      e.target.value = e.target.value.replace(/[^\d]/g, '');
    }

    setRegAccnInfo((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const handleChangeIbanYn = (e) => {
    const selVal = e.target.value;
    setRegAccnInfo((prev) => {
      return {
        ...prev,
        ibanYn: selVal,
        ibanCd: selVal == 'IBAN' ? prev.ibanCd : '',
        // bankNoTpCd: selVal == 'IBAN' ? '2' : '', //  sortCode  //0404 수정
        bankNoTpCd: '', //  초기화
        encAccnNo: selVal == 'IBAN' ? '' : prev.encAccnNo,
        addAtrValCtn06: 'N',
        isDpstNmCertRslt: 'N',
      };
    });
  };

  const handleBlurSwiftCd = (e) => {
    const tmpVal = e.target.value;
    if (tmpVal.length >= 6) {
      const tmpBankLoctCtryCd = tmpVal.substring(4, 6);
      if ('KR' == tmpBankLoctCtryCd) {
        getFrgnBankCd(tmpVal);
      } else {
        setRegAccnInfo((prev) => {
          return { ...prev, isDpstNmCert: props.isKrw ? 'Y' : 'N', bankCd: '' };
        });
      }
    } else {
      setRegAccnInfo((prev) => {
        return { ...prev, isDpstNmCert: props.isKrw ? 'Y' : 'N', bankCd: '' };
      });
    }
  };

  /**
   * 통화
   * @param e
   */
  const handleChangeCurrCd = (e) => {
    setRegAccnInfo((prev) => {
      return {
        ...prev,
        currCd: e.target.value,
        addAtrValCtn06: 'N',
        isDpstNmCertRslt: 'N',
      };
    });
  };

  /**
   * 요청구분
   * @param e
   */
  const handleChangeWktDivsCd = (e) => {
    setRegAccnInfo((prev) => {
      return {
        ...prev,
        wktDivsCd: e.target.value,
      };
    });
  };

  /**
   * 은행소재지
   * @param e
   */
  const handleChangeBankLoctCtryCd = (e) => {
    setRegAccnInfo((prev) => {
      return {
        ...prev,
        bankLoctCtryCd: e.target.value,
        bankCtryCd: e.target.value,
        // isDpstNmCert: e.target.value == 'KR' ? 'Y' : 'N',
        swftCd: '',
      };
    });
  };

  /**
   * 계좌 추가정보
   * @param e
   */
  const handleChangeBankNoTpCd = (e) => {
    const selectedBankNm = bankNoTpCdList.find((name) => name.cmnCd === e.target.value);
    const findBankTpCdNm = selectedBankNm ? selectedBankNm.cmnCdNm : '';
    setChgBankTpCdNm(findBankTpCdNm);
    setRegAccnInfo((prev) => {
      return {
        ...prev,
        bankNoTpCd: e.target.value,
        bankNo: e.target.value == '9' || e.target.value == '' ? '' : prev.bankNo,
      };
    });
  };

  const getFrgnBankCd = async (swiftCd) => {
    openLoading(true);

    if (dmstFrgDivsCd == 'DO') {
      getSapIfStdInfo({
        I_REQ_DIVS: 'BK',
        I_SWIFT: swiftCd,
        I_LAND: props.isKrw ? 'KR' : '',
      }).then((resp) => {
        openLoading(false);
        if (resp.length > 0) {
          const tmpBankCd = resp.filter((o) => o.bgrup != null)[0].bgrup;
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: tmpBankCd ?? '',
              isDpstNmCert: tmpBankCd ? 'Y' : 'N',
            };
          });
        } else {
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: '',
              isDpstNmCert: props.isKrw ? 'Y' : 'N', // 계좌인증
            };
          });
        }
      });
    } else {
      getSapIfStdInfoG({
        I_REQ_DIVS: 'BK',
        I_SWIFT: swiftCd,
        I_LAND: props.isKrw ? 'KR' : '',
      }).then((resp) => {
        openLoading(false);
        if (resp.length > 0) {
          const tmpBankCd = resp.filter((o) => o.bgrup != null)[0].bgrup;
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: tmpBankCd ?? '',
              isDpstNmCert: tmpBankCd ? 'Y' : 'N',
            };
          });
        } else {
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: '',
              isDpstNmCert: props.isKrw ? 'Y' : 'N', // 계좌인증
            };
          });
        }
      });
    }
  };

  /**
   * 단위 파일id 가져오기
   */
  const getFileId = useCallback(async (dropzoneRef) => {
    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    return fileSaveResponse.fileSaveResult == FileSaveResult.FAIL
      ? null
      : fileSaveResponse.atchFileGrId;
  }, []);

  /**
   * 첨부파일 정보
   */
  const getAccountFileIds = async () => {
    if (fileDropzoneRef1.current.getFiles().length) {
      await getFileId(fileDropzoneRef1).then((respFileId) => {
        if (respFileId) {
          files.fileId1 = respFileId;
          setRegAccnInfo((prev) => {
            return { ...prev, fileId1: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef2.current.getFiles().length) {
      await getFileId(fileDropzoneRef2).then((respFileId) => {
        if (respFileId) {
          files.fileId2 = respFileId;
          setRegAccnInfo((prev) => {
            return { ...prev, fileId2: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef3.current.getFiles().length) {
      await getFileId(fileDropzoneRef3).then((respFileId) => {
        if (respFileId) {
          files.fileId3 = respFileId;
          setRegAccnInfo((prev) => {
            return { ...prev, fileId3: respFileId };
          });
        }
      });
    }
    // if (fileDropzoneRef4.current.getFiles().length) {
    //   await getFileId(fileDropzoneRef4).then((respFileId) => {
    //     if (respFileId) {
    //       files.fileId4 = respFileId;
    //       setRegAccnInfo((prev) => {
    //         return { ...prev, fileId4: respFileId };
    //       });
    //     }
    //   });
    // }
    if (fileDropzoneRef5.current.getFiles().length) {
      await getFileId(fileDropzoneRef5).then((respFileId) => {
        if (respFileId) {
          files.fileId5 = respFileId;
          setRegAccnInfo((prev) => {
            return { ...prev, fileId5: respFileId };
          });
        }
      });
    }
  };

  /**
   * 예금주 인증
   * @returns
   */
  const handleValidAccnClick = async () => {
    if (!regAccnInfo.bankCd) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00729', '__SWIFT/BIC를 입력하세요.'),
      });
      return;
    }

    if (!regAccnInfo.encAccnNo) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00539', '__계좌번호를 입력하세요.'),
      });
      return;
    }

    if (!regAccnInfo.dpstNm) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00540', '__예금주를 입력하세요.'),
      });
      return;
    }

    if (!regAccnInfo.currCd) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00541', '__통화를 입력하세요.'),
      });
      return;
    }

    const accnValidParam = {
      ZBNKY: regAccnInfo.bankCd,
      ZBNKN: regAccnInfo.encAccnNo,
      ZNME1: regAccnInfo.dpstNm,
      WAERS: regAccnInfo.currCd,
    } as AccountValidVO;

    if (!props.vendorInfo.encRgno && !props.vendorInfo.bzno) {
      openMessageBar('warning', t('com.label.00441', '__인증에 실패하였습니다 .'));
      setRegAccnInfo((prev) => {
        return {
          ...prev,
          isDpstNmCertRslt: 'Y',
        };
      });
      return;
    }

    openLoading(true);
    await getAccnValidInfo(accnValidParam)
      .then((resp) => {
        openLoading(false);
        if (resp.result == 'S') {
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              addAtrValCtn06: 'Y', //예금주 확인 완료 여부
              addAtrValCtn08: resp.result, //예금주 인증처리 결과코드
              addAtrValCtn09: resp.e_ZNME1, //예금주 인증처리 결과내용
              addDpstNm: resp.e_ZNME1, //예금주 확인 필드
              isDpstNmCertRslt: 'Y',
            };
          });
          openMessageBar('success', t('com.label.00040', '__인증에 성공하였습니다.'));
        } else {
          // if (!props.isKrw) {
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              addAtrValCtn08: resp.result ?? '', //예금주 인증처리 결과코드
              addAtrValCtn09: resp.e_ZNME1 ?? '', //예금주 인증처리 결과내용
              addDpstNm: resp.e_ZNME1 ?? '', //예금주 확인 필드
              isDpstNmCertRslt: 'Y',
            };
          });
          // }
          alert(resp.error_INFO);
        }
      })
      .catch((e) => {
        console.error('error', e);
        openLoading(false);
      });
  };

  /**
   *  중복계좌 번호 체크 로직
   */
  const validDuplAccn = () => {
    let duplAccn;
    if (regAccnInfo.ibanYn == 'Normal') {
      duplAccn = props.accounts.filter((o) => o.encAccnNo == regAccnInfo.encAccnNo?.trim());
    } else {
      duplAccn = props.accounts.filter((o) => o.ibanCd == regAccnInfo.ibanCd?.trim());
    }

    if (duplAccn.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * 선택한 통화와 동일한 통화가 진행중 상태에 있는지 여부 체크
   */
  const validDuplCurr = () => {
    //0327 주석 제거
    //0329 수정
    // let pAccn;
    // // 추가계좌 가능
    // if (props.vendorInfo.oseaAccnAddYn != 'Y') {
    //   pAccn = props.accounts.filter(
    //     (o) => o.accnRegProgStatCd == 'P' || o.accnRegProgStatCd == 'T'
    //   );
    // } else {
    //   pAccn = props.accounts;
    // }
    const duplAccn = props.accounts.filter((o) => o.currCd == regAccnInfo.currCd);

    if (duplAccn.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * 첨부파일 필수여부 체크
   */
  const validFiles = (arrRef, arrMsg) => {
    let flag = true;
    // return arrRef.every((o) => o.current.getFiles().length);

    for (let index = 0; index < arrRef.length; index++) {
      const o = arrRef[index];
      if (!o.current.getFiles().filter((o) => o.useYn == 'Y').length) {
        openCommonModal({
          modalType: 'alert',
          content: `${arrMsg[index]} ${t('com.label.00545', '__첨부파일을 추가하세요')}`,
        });
        flag = false;
        break;
      }
    }

    return flag;
  };

  const validInput = async (regAccnInfo) => {
    let infoFlag = false;
    regSchema
      .validate(regAccnInfo)
      .then(() => {
        infoFlag = true;
        return infoFlag;
      })
      .catch((e) => {
        console.log('error : ', e);
        openCommonModal({
          modalType: 'alert',
          content: e.message,
        });
        return infoFlag;
      });
  };

  /**
   * 계좌정보 저장
   * @returns
   */
  const handleAccnSaveClick = async () => {
    // if (validDuplAccn()) {
    //   openCommonModal({
    //     modalType: 'alert',
    //     content: t('com.label.00542', '__중복된 계좌를 등록할 수 없습니다.'),
    //   });
    //   return;
    // }

    if (!emailValid()) return;

    if (validDuplCurr()) {
      // 통화가있음.
      // 상태

      if (props.isKrw) {
        openCommonModal({
          modalType: 'alert',
          content: t('com.label.00680', '__추가 계좌를 요청할 수 없습니다.'),
        });
        return;
      }

      const duplCurrStatCd = props.accounts.filter((o) => o.currCd == regAccnInfo.currCd)[0]
        .accnRegProgStatCd;

      if (duplCurrStatCd == 'C') {
        // 통화가 완료상태이면 wkt를 'A'로 설정하도록

        if (props.vendorInfo.oseaAccnAddYn != 'Y') {
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00680', '__추가 계좌를 요청할 수 없습니다.'),
          });
          return;
        }

        if (regAccnInfo.wktDivsCd != 'A') {
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00681', '__요청구분을 A로 선택해주세요'),
          });
          return;
        }
      } else {
        openCommonModal({
          modalType: 'alert',
          content: t('com.label.00452', '__선택한 통화와 동일한 계좌가 처리진행 중입니다'),
        });
        return;
      }
    } else {
      // 미등록 계좌임.
      if (props.vendorInfo.oseaAccnAddYn == 'Y') {
        if (regAccnInfo.wktDivsCd == 'A') {
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00730', '__요청구분을 New로 선택해주세요'),
          });
          return;
        }
      }
    }

    if (
      !validFiles(
        [fileDropzoneRef1, fileDropzoneRef2, fileDropzoneRef3],
        [
          t('com.label.00544', '__계좌등록요청서'),
          t('com.label.00659', '__은행계좌확인서 또는 계좌사본(인증시)'),
          t('com.label.00284', '__국가별법인등록증'),
        ] //, '기타1', '기타2'
      )
    ) {
      return;
    }

    // TODO 예금주 확인
    if (regAccnInfo.isDpstNmCert == 'Y') {
      if (regAccnInfo.isDpstNmCertRslt != 'Y') {
        openCommonModal({
          modalType: 'alert',
          content: '계좌를 인증해주세요',
        });
        return;
      }
    }

    const schema = props.isKrw ? regSchemaKrw : dmstFrgDivsCd == 'DO' ? regSchemaDo : regSchema;

    schema
      .validate(regAccnInfo)
      .then(async () => {
        // 2차 검수 피드백 : 저장노티 띄우지 않고 바로 '저장되었습니다' 메세지 노출
        // 수정자 : 양지원
        try {
          openLoading(true);

          if (!props.isKrw) {
            // 외화 등록인 경우 (해외 사용자 원화 등록이 아닌 경우)
            if (dmstFrgDivsCd == 'DO') {
              console.log('국내 외화', regAccnInfo);
              // 1. 국내 사용자 외화 등록인 경우

              // const resultValid = await getAccnValid0237({
              //   I_REQ_DIVS: 'FOR', // 원화 외화 구분
              //   I_LIFNR: props.vendorInfo.vdcpCd, //구매처코드
              //   I_MODE: 'N', //변경유형
              //   I_BANKS_N: regAccnInfo.bankLoctCtryCd, //신규 은행국가키
              //   I_BANKL_N: regAccnInfo.bankKeyCd ?? '', // 신규은행키
              //   I_BANKN_N: regAccnInfo.encAccnNo, //신규_은행계좌번호
              //   I_BVTYP_N: regAccnInfo.currCd, // 신규은행통화
              //   I_KOINH_N: regAccnInfo.dpstNm, // 신규예금주명
              //   I_KOINH_EXT_N: regAccnInfo.addDpstNm, // 신규 계정보유자이름 (추가 예금주명?)
              //   I_BKONT_N: regAccnInfo.bankCd, //신규_은행코드(원화)
              // });

              // if (resultValid.stat == 'S') {
              //   setRegAccnInfo((prev) => {
              //     return {
              //       ...prev,
              //       accnNoCertYn: 'Y', // 계좌인증여부
              //     };
              //   });
              //   setCertFlag(true);
              // } else {
              //   openCommonModal({
              //     modalType: 'alert',
              //     content: resultValid.msg,
              //   });
              //   return;
              // }
            } else {
              console.log('해외 외화');
              // 2. 해외 사용자 외화 등록인 경우 GFI0237

              // const resultValid = await getAccnValidG0237({
              //   I_REQ_DIVS: 'FOR', // 원화 외화 구분
              //   I_LIFNR: props.vendorInfo.vdcpCd, //구매처코드
              //   I_MODE: regAccnInfo.wktDivsCd,
              //   I_BANKS_N: regAccnInfo.bankLoctCtryCd, //신규_은행국가키 	FOR(필수)
              //   I_BANKL_N: regAccnInfo.bankKeyCd ?? '', //신규_은행 키 	FOR(필수)
              //   I_BANKN_N: regAccnInfo.encAccnNo, //신규_은행계좌번호 	FOR(필수)
              //   I_WAERS_N: regAccnInfo.currCd, //신규_계좌통화	FOR(필수)
              //   I_KOINH_N: regAccnInfo.dpstNm, //신규_예금주명	FOR(필수)
              // });
              // if (resultValid.stat == 'S') {
              //   setRegAccnInfo((prev) => {
              //     return {
              //       ...prev,
              //       accnNoCertYn: 'Y', // 계좌인증여부
              //     };
              //   });
              //   setCertFlag(true);
              // } else {
              //   // TODO PASS
              //   openCommonModal({
              //     modalType: 'alert',
              //     content: resultValid.msg,
              //   });
              //   return;
              // }
            }
          } else {
            // 해외사용자 원화 등록인 경우 GFI0237
            // const resultValid = await getAccnValidG0237({
            //   I_REQ_DIVS: 'FOR', // 원화 외화 구분
            //   I_LIFNR: props.vendorInfo.vdcpCd, //구매처코드
            //   I_MODE: regAccnInfo.wktDivsCd,
            //   I_BANKS_N: regAccnInfo.bankLoctCtryCd, //신규_은행국가키 	FOR(필수)
            //   I_BANKL_N: regAccnInfo.bankKeyCd ?? '', //신규_은행 키 	FOR(필수)
            //   I_BANKN_N: regAccnInfo.encAccnNo, //신규_은행계좌번호 	FOR(필수)
            //   I_WAERS_N: regAccnInfo.currCd, //신규_계좌통화	FOR(필수)
            //   I_KOINH_N: regAccnInfo.dpstNm, //신규_예금주명	FOR(필수)
            //   I_BUKRS_N: props.vendorInfo.soprMgrCopCd, // Company Code	FOR(필수)
            //   I_BUKRS_O: props.vendorInfo.soprMgrCopCd, // Company Code	FOR(I_MODE=U/A 일때 필수)
            //   I_IBAN_N: regAccnInfo.ibanCd, // IBAN (International Bank Account Number)	FOR(I_ZTYPE = 'I'일때만필수)
            //   I_ZTYPE: regAccnInfo.ibanYn == 'Normal' ? 'N' : 'I', // [BIZWF] 계좌구분(I /N)	FOR(필수)
            // });
            // if (resultValid.stat == 'S') {
            //   setRegAccnInfo((prev) => {
            //     return {
            //       ...prev,
            //       accnNoCertYn: 'Y', // 계좌인증여부
            //     };
            //   });
            //   setCertFlag(true);
            // } else {
            //   openCommonModal({
            //     modalType: 'alert',
            //     content: resultValid.msg,
            //   });
            //   return;
            // }
          }

          await getAccountFileIds();
          await registForeignAccount(regAccnInfo, files, props.frgnDftAccount).then((resp) => {
            if (resp.successOrNot === 'Y') {
              openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
              handleClose(true);
            }
          });
        } catch (e) {
          console.log('e', e);
          return;
        } finally {
          openLoading(false);
        }
      })
      .catch((e) => {
        console.log('error : ', e);
        openCommonModal({
          modalType: 'alert',
          content: e.message,
        });
        return;
      });
  };

  useEffect(() => {
    let tmpWktDivsCdList = [
      { cmnCdNm: t('com.code.WKT_DIVS_CD.N', '__New'), cmnCd: 'N' },
      { cmnCdNm: t('com.code.WKT_DIVS_CD.A', '__Addition'), cmnCd: 'A' },
    ];

    if (props.isKrw || props.vendorInfo.oseaAccnAddYn != 'Y') {
      tmpWktDivsCdList = [{ cmnCdNm: t('com.code.WKT_DIVS_CD.N', '__New'), cmnCd: 'N' }];
    }

    setWktDivsCdList(tmpWktDivsCdList);
    (async () => {
      // 공통코드 조회 (계좌추가정보)
      if (!props.isKrw && dmstFrgDivsCd == 'OV') {
        await getJwtCommonCodes('BANK_NO_TP_CD').then((resp) => {
          setBankNoTpCdList(resp);

          // if (props.isKrw) {
          //   setRegAccnInfo((prev) => {
          //     return { ...prev, bankNoTpCd: '9' };
          //   });
          // } else {
          //   setRegAccnInfo((prev) => {
          //     return { ...prev, bankNoTpCd: resp[0].cmnCd };
          //   });
          // }
        });
      }

      // 공통코드 조회 (국가키)
      await getJwtCommonCodes('BANK_LOCT_CTRY_CD').then((resp) => {
        setBankLoctCtryCdList(resp);
        if (props.isKrw) {
          setRegAccnInfo((prev) => {
            return { ...prev, bankLoctCtryCd: 'KR', bankCtryCd: 'KR' };
          });
        } else {
          // setRegAccnInfo((prev) => {
          //   return { ...prev, bankLoctCtryCd: resp[0].cmnCd, bankCtryCd: resp[0].cmnCd };
          // });
        }
      });

      if (!props.isKrw) {
        // 외화 등록인 경우 (해외 사용자 원화 등록이 아닌 경우)
        if (dmstFrgDivsCd == 'DO') {
          // 국내사용자의 외화등록
          await getSapIfStdInfo({ I_REQ_DIVS: 'CUR' }).then((resp) => {
            if (resp.length > 0) {
              const computedArr = resp.reduce((acc, obj) => {
                const newObj = {
                  cmnCd: obj.waers,
                  cmnCdNm: obj.ltext,
                };
                if (obj.waers != 'KRW') {
                  acc.push(newObj);
                }
                return acc;
              }, []);
              setCurCdList(computedArr);
            }
          });
        } else {
          // 해외 사용자의 외화등록
          await getSapIfStdInfoG({ I_REQ_DIVS: 'CUR' }).then((resp) => {
            if (resp.length > 0) {
              const computedArr = resp.reduce((acc, obj) => {
                const newObj = {
                  cmnCd: obj.waers,
                  cmnCdNm: obj.ltext,
                };
                if (obj.waers != 'KRW') {
                  acc.push(newObj);
                }
                return acc;
              }, []);
              setCurCdList(computedArr);
            }
          });
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (certFlag) {
      setRegAccnInfo((prev) => {
        return {
          ...prev,
          accnNoCertYn: 'Y', // 계좌인증여부
        };
      });
    }
  }, [certFlag]);

  useEffect(() => {
    if (regAccnInfo.bankCd) {
      setRegAccnInfo((prev) => {
        return {
          ...prev,
          isDpstNmCert: 'Y', // 계좌인증
        };
      });
    } else {
      setRegAccnInfo((prev) => {
        return {
          ...prev,
          isDpstNmCert: props.isKrw ? 'Y' : 'N', // 계좌인증
        };
      });
    }
  }, [regAccnInfo.bankCd]);

  // PIF 출력폼
  const { handleClickPif, setBankInfo, setBasicVendorInfo, setChgBankTpCdNm } = usePifPrint();

  useEffect(() => {
    setBasicVendorInfo(props.vendorInfo);
  }, []);

  useEffect(() => {
    setBankInfo(regAccnInfo);
  }, [regAccnInfo]);

  return (
    <>
      <ModalLayout
        open={props.open}
        onClose={() => {
          handleClose(false);
        }}
        mMinWidth={1000}
        mMaxWidth={1000}
      >
        <DialogTitleItem
          id="customized-dialog-title"
          onClose={() => {
            handleClose(false);
          }}
        >
          <GPopupTitle title2={t('com.label.00061', '__계좌정보등록')} />
        </DialogTitleItem>
        <DialogContent className="popupContent">
          {!props.isKrw && (
            <GbasicTableWrap isBottomMgn={false} style={{ marginBottom: '12px' }}>
              <GbasicTable>
                <colgroup>
                  <col style={{ width: '160px' }} />
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: '160px' }} />
                  <col style={{ width: 'auto' }} />
                </colgroup>
                <tbody>
                  <>
                    <tr>
                      <th>
                        <GLabelAtomVars
                          align="left"
                          isInfo={true}
                          pTxt={
                            t(
                              'com.tooltip.accn.fcur.reg05',
                              '__ㆍ New: When you are registering a new bank account.\r\nㆍ Addition: If you already have an account registered in the same currency and you want to add another account.'
                            ) ?? ''
                          }
                          pPlace="3"
                        >
                          {t('com.code.reqClsf', '__요청구분')}
                        </GLabelAtomVars>
                      </th>
                      <td>
                        <GCodeSelect
                          id={'cmnCd'}
                          labelKey={'cmnCdNm'}
                          listData={wktDivsCdList}
                          onlyShowName={true}
                          value={regAccnInfo.wktDivsCd}
                          onChange={handleChangeWktDivsCd}
                          width="150px"
                        />
                      </td>
                    </tr>
                    {/* <tr style={{ height: '8px' }}></tr> */}
                  </>
                </tbody>
              </GbasicTable>
            </GbasicTableWrap>
          )}
          <GbasicTableWrap>
            <GbasicTable>
              <tbody>
                {dmstFrgDivsCd == 'OV' && !props.isKrw && (
                  <tr>
                    <th>
                      <GLabelAtom align="left">{t('com.code.accnTp', '__계좌유형')}</GLabelAtom>
                    </th>
                    <td>
                      <GRadioGroup
                        value={regAccnInfo.ibanYn || 'Normal'}
                        option={['Normal', 'IBAN']}
                        onChange={handleChangeIbanYn}
                      />
                    </td>
                  </tr>
                )}

                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.label.00067', '__예금주')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="dpstNm"
                      status="default"
                      align="left"
                      width="100%"
                      maxLength={60}
                      // placeholder={t2('com.label.00068', '__입력하세요')}
                      onChange={handleChangeForRegAccn}
                    />
                  </td>
                  <th>
                    <GLabelAtom align="left">{t('com.label.00069', '__예금주 확인')}</GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="addDpstNm"
                      status="readonly"
                      readOnly
                      align="left"
                      width="100%"
                      value={regAccnInfo.addDpstNm}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired={regAccnInfo.ibanYn == 'Normal'}>
                      {t('com.label.00070', '__계좌번호')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <Wrap>
                      <InputField
                        id="encAccnNo"
                        status={regAccnInfo.ibanYn == 'Normal' ? 'default' : 'readonly'}
                        readOnly={regAccnInfo.ibanYn != 'Normal'}
                        value={regAccnInfo.encAccnNo}
                        align="left"
                        width="100%"
                        placeholder={
                          regAccnInfo.ibanYn == 'Normal'
                            ? t2('com.label.00377', `__'-'없이 입력하세요`)
                            : ''
                        }
                        maxLength={100}
                        onChange={handleChangeForRegAccn}
                      />
                      {regAccnInfo.bankLoctCtryCd == 'KR' && regAccnInfo.isDpstNmCert == 'Y' && (
                        <GButton
                          txt={t(`com.label.00548`, `__인증요청`)}
                          sizes="medium"
                          btnColor="normal"
                          btnstyled="outline"
                          hasImg={false}
                          hasTxt={true}
                          onClick={() => {
                            handleValidAccnClick();
                          }}
                        />
                      )}
                    </Wrap>
                  </td>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.label.00076', '__통화')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <GCodeSelect
                      id={'cmnCd'}
                      labelKey={'cmnCd'}
                      // emptyLabel={props.isKrw ? 'KRW' : 'USD'}
                      listData={props.isKrw ? [{ cmnCdNm: 'KRW', cmnCd: 'KRW' }] : curCdList}
                      onlyShowName={true}
                      value={regAccnInfo.currCd}
                      onChange={handleChangeCurrCd}
                      width="150px"
                      readonly={props.isKrw}
                    />
                  </td>
                </tr>

                {dmstFrgDivsCd == 'OV' && !props.isKrw && (
                  <tr>
                    <th>
                      <GLabelAtom align="left" isRequired={regAccnInfo.ibanYn != 'Normal'}>
                        IBAN Code
                      </GLabelAtom>
                    </th>
                    <td>
                      <InputField
                        id="ibanCd"
                        status={regAccnInfo.ibanYn == 'Normal' ? 'readonly' : 'default'}
                        readOnly={regAccnInfo.ibanYn == 'Normal'}
                        align="left"
                        width="100%"
                        value={regAccnInfo.ibanCd}
                        placeholder={
                          regAccnInfo.ibanYn == 'Normal'
                            ? ''
                            : t2('com.label.00068', '__입력하세요')
                        }
                        maxLength={34}
                        onChange={handleChangeForRegAccn}
                      />
                    </td>
                    <th>
                      <GLabelAtomVars
                        align="left"
                        isInfo={true}
                        pTxt={t2(
                          'com.tooltip.accn.fcur.reg04',
                          '__ㆍSelect and enter ABA Routing No.(EFT Routing No.), Sort Code, CNAPS Code'
                        )}
                        pPlace="3"
                        isRequired
                      >
                        {t('com.code.bankAddIfo', '__계좌추가정보')}
                      </GLabelAtomVars>
                    </th>
                    <td>
                      <Wrap>
                        <GCodeSelect
                          id={'cmnCd'}
                          labelKey={'cmnCdNm'}
                          listData={bankNoTpCdList}
                          onlyShowName={true}
                          emptyLabel={t2('com.label.00356', '__선택')}
                          value={regAccnInfo.bankNoTpCd}
                          onChange={handleChangeBankNoTpCd}
                          width="108px"
                          // readonly={props.isKrw || regAccnInfo.ibanYn == 'IBAN'} // 0404 수정
                          readonly={props.isKrw}
                        />
                        <InputField
                          id="bankNo"
                          status={
                            regAccnInfo.bankNoTpCd == '' || regAccnInfo.bankNoTpCd == '9'
                              ? 'readonly'
                              : 'default'
                          }
                          align="left"
                          width="100%"
                          readOnly={regAccnInfo.bankNoTpCd == '' || regAccnInfo.bankNoTpCd == '9'}
                          onChange={handleChangeForRegAccn}
                          maxLength={15}
                        />
                      </Wrap>
                    </td>
                  </tr>
                )}

                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.label.00065', '__은행')}
                    </GLabelAtom>
                  </th>
                  <td>
                    {/* <ChipInputBox
                      type="singleChip"
                      width="100%"
                      status="default"
                      value={regAccnInfo.bankNm}
                      setValue={(value) => {
                        if (!value) {
                          setRegAccnInfo((prev) => {
                            return {
                              ...prev,
                              bankKeyCd: '',
                              bankNm: '',
                              bankCtryCd: props.isKrw ? 'KR' : '',
                              swftCd: '',
                            };
                          });
                        }
                      }}
                      onClickFunction={() => {
                        setBankNumberModalOpen(true);
                      }}
                    /> */}
                    <InputField
                      id="bankNm"
                      status="default"
                      align="left"
                      width="100%"
                      value={regAccnInfo.bankNm}
                      onChange={handleChangeForRegAccn}
                      maxLength={100}
                    />
                  </td>
                  <th>
                    <GLabelAtom
                      align="left"
                      isRequired={props.isKrw || regAccnInfo.dmstFrgDivsCd == 'DO'}
                    >
                      SWIFT/BIC
                    </GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="swftCd"
                      status="default"
                      align="left"
                      width="100%"
                      value={regAccnInfo.swftCd}
                      onChange={handleChangeForRegAccn}
                      onBlur={(e) => {
                        handleBlurSwiftCd(e);
                        e.stopPropagation();
                      }}
                      maxLength={15}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.code.bankCtry', '__은행소재국가')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <GCodeSelect
                      id={'cmnCd'}
                      labelKey={'cmnCdNm'}
                      listData={bankLoctCtryCdList}
                      emptyLabel={t2('com.label.00356', '__선택')}
                      onlyShowName={true}
                      value={regAccnInfo.bankLoctCtryCd}
                      onChange={handleChangeBankLoctCtryCd}
                      width="150px"
                      readonly={props.isKrw}
                    />
                  </td>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.code.bankAddr', '__은행주소')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="bankAddr"
                      status="default"
                      align="left"
                      width="100%"
                      value={regAccnInfo.bankAddr}
                      maxLength={200}
                      onChange={handleChangeForRegAccn}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.code.bankCntNum', '__은행연락처')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="bankCtpl"
                      status="default"
                      align="left"
                      width="100%"
                      value={regAccnInfo.bankCtpl}
                      maxLength={15}
                      onChange={handleChangeForRegAccn}
                    />
                  </td>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t2('com.code.bankEmal', '__은행이메일')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="bankEml"
                      status="default"
                      align="left"
                      width="100%"
                      value={regAccnInfo.bankEml}
                      maxLength={30}
                      onChange={handleChangeForRegAccn}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtom align="left">{t('com.label.00071', '__계좌용도')}</GLabelAtom>
                  </th>
                  <td colSpan={3}>
                    <InputField
                      id="usePurpCtn"
                      status="default"
                      align="left"
                      width="100%"
                      // placeholder={t2('com.label.00068', '__입력하세요')}
                      maxLength={25}
                      onChange={handleChangeForRegAccn}
                    />
                  </td>
                </tr>
              </tbody>
            </GbasicTable>
          </GbasicTableWrap>
          <GbasicTableWrap style={{ marginTop: '12px' }}>
            <GbasicTable>
              <tbody>
                <tr>
                  <th>
                    <GLabelAtomVars
                      align="left"
                      isRequired
                      isInfo={true}
                      pTxt={
                        t(
                          'com.tooltip.accn.fcur.reg01',
                          `__ㆍ상단 "Payment Information Form 출력"을 클릭하여 Payment Information Form에 기재된 내용의 이상 여부 확인\r\nㆍ기재된 내용에 이상이 없는 경우 서류를 출력하여 법인인감 날인(한국 소재 기업) 또는 서명하여 첨부하세요.`
                        ) ?? ''
                      }
                      pPlace="3"
                    >
                      {t('com.label.00544', '__계좌등록요청서')}
                    </GLabelAtomVars>
                  </th>
                  <td colSpan={3}>
                    <FileUploadVars
                      ref={fileDropzoneRef1}
                      height="40px"
                      atchFileGrIdInput=""
                      isMultipleFile={false}
                      allowFileTypes={['jpg', 'png', 'pdf']}
                      width="100%"
                      maxCount={1}
                      getSelectedFile={(value) => {
                        if (value.length == 0) {
                          files.fileId1 = '';
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th rowSpan={2}>
                    <GLabelAtomVars
                      align="left"
                      isRequired
                      isInfo={true}
                      pTxt={
                        t(
                          'com.tooltip.accn.fcur.reg02',
                          `__은행으로부터 발급받은 은행계좌확인서\r\nㆍ 은행담당자 서명 필수\r\nㆍ 한국 소재 기업인 경우 통장 사본 함께 첨부`
                        ) ?? ''
                      }
                      pPlace="3"
                    >
                      {t('com.label.00659', '__은행계좌확인서 또는 계좌사본(인증시)')}
                    </GLabelAtomVars>
                  </th>
                  <td>
                    <GButton
                      txt={t('com.code.smpDown', '__샘플 다운로드')}
                      chkImg={Download}
                      btnstyled="outline"
                      btnColor="normal"
                      sizes="small"
                      hasTxt={true}
                      hasImg={true}
                      disabled={false}
                      onClick={() => {
                        const url = '/assets/file/AccountComfirmationSample.pdf';
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'AccountComfirmationSample.pdf');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <FileUploadVars
                      ref={fileDropzoneRef2}
                      height="40px"
                      atchFileGrIdInput=""
                      isMultipleFile={false}
                      allowFileTypes={['jpg', 'png', 'pdf']}
                      width="100%"
                      maxCount={1}
                      getSelectedFile={(value) => {
                        if (value.length == 0) {
                          files.fileId2 = '';
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th rowSpan={2}>
                    <GLabelAtomVars
                      align="left"
                      isRequired
                      isInfo={true}
                      pTxt={
                        t(
                          'com.tooltip.accn.fcur.reg03',
                          '__ㆍ W-9, 사업자등록증 등\r\nㆍ 한국 소재 기업인 경우 3개월 이내 발급한 법인인감증명서 함께 첨부 필수'
                        ) ?? ''
                      }
                      pPlace="3"
                    >
                      {t('com.label.00284', '__국가별법인등록증')}
                    </GLabelAtomVars>
                  </th>
                  <td>
                    <GButton
                      txt={t('com.code.smpDown', '__샘플 다운로드')}
                      chkImg={Download}
                      btnstyled="outline"
                      btnColor="normal"
                      sizes="small"
                      hasTxt={true}
                      hasImg={true}
                      disabled={false}
                      onClick={() => {
                        const url = '/assets/file/sample.pdf';
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'sample.pdf');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <FileUploadVars
                      ref={fileDropzoneRef3}
                      height="40px"
                      atchFileGrIdInput=""
                      isMultipleFile={false}
                      allowFileTypes={['jpg', 'png', 'pdf']}
                      width="100%"
                      maxCount={1}
                      getSelectedFile={(value) => {
                        if (value.length == 0) {
                          files.fileId3 = '';
                        }
                      }}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <th>
                    <GLabelAtomVars align="left">{t('com.label.00075', '__기타')}1</GLabelAtomVars>
                  </th>
                  <td colSpan={3}>
                    <FileUploadVars
                      ref={fileDropzoneRef4}
                      height="40px"
                      atchFileGrIdInput=""
                      isMultipleFile={true}
                      allowFileTypes={['jpg', 'png', 'xlsx', 'pdf']}
                      width="100%"
                      maxCount={5}
                      getSelectedFile={(value) => {
                        if (value.length == 0) {
                          files.fileId4 = '';
                        }
                      }}
                    />
                  </td>
                </tr> */}
                <tr>
                  <th>
                    <GLabelAtomVars align="left">{t('com.label.00075', '__기타')}</GLabelAtomVars>
                  </th>
                  <td colSpan={3}>
                    <FileUploadVars
                      ref={fileDropzoneRef5}
                      height="40px"
                      atchFileGrIdInput=""
                      isMultipleFile={true}
                      allowFileTypes={['jpg', 'png', 'xlsx', 'pdf']}
                      width="100%"
                      maxCount={5}
                      getSelectedFile={(value) => {
                        if (value.length == 0) {
                          files.fileId5 = '';
                        }
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </GbasicTable>
          </GbasicTableWrap>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GButtonBox>
            <GButton
              txt={t('com.label.prntPif', '__Payment Information Form 출력')}
              sizes="medium"
              btnstyled="outline"
              btnColor="normal"
              hasImg={false}
              hasTxt={true}
              // chkImg={Delete} 프린터기 이미지 확인필요
              onClick={handleClickPif}
            ></GButton>
            <GButton
              txt={t('com.label.00036', '취소')}
              sizes="medium"
              onClick={() => {
                handleClose(false);
              }}
              btnColor="normal"
              btnstyled="outline"
              hasImg={false}
              hasTxt={true}
            />
            <GButton
              txt={t('com.label.00055', '저장')}
              chkImg={Save}
              sizes="medium"
              onClick={handleAccnSaveClick}
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </GButtonBox>
        </DialogActions>
      </ModalLayout>
      {/* {props.isKrw ? (
        <BankNumberModal
          open={bankNumberModalOpen}
          close={() => setBankNumberModalOpen(false)}
          save={(data) => {
            setRegAccnInfo((prev) => {
              return {
                ...prev,
                bankCd: data.bankCode,
                bankNm: data.bankName,
              };
            });
            // regAccnInfo.bankCd = data.bankCode;
            console.log(data);
          }}
        />
      ) : ( */}
      <ForeignCurrencyBank
        open={bankNumberModalOpen}
        close={() => setBankNumberModalOpen(false)}
        isKrw={props.isKrw}
        save={(data) => {
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              bankKeyCd: data.bankl,
              bankNm: data.banka,
              bankCtryCd: data.banks,
              swftCd: data.swift,
              bgrup: data.bgrup,
              isDpstNmCert: data.banks == 'KR' && data.bgrup.length == 2 ? 'Y' : 'N',
            };
          });
        }}
        vendorInfo={props.vendorInfo}
      />
      {/* )} */}
    </>
  );
};

const Wrap = styled.div`
  display: flex;
  gap: 4px;
`;

export default DmsRegFrgnAcntModal;
