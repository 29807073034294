import styled from '@emotion/styled';
import Save from 'components/asset/images/Confirm.svg';
import Delete from 'components/asset/images/Delete.svg';
import { GButton } from 'components/atom/button';
import { GLabelAtom } from 'components/atom/label';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import InputField from 'components/atom/input';
import { Input, SelectChangeEvent } from '@mui/material';
import SubTitleBox from 'components/molecule/SubTitleBox';
import GridRadio, { RadioWrap } from 'components/molecule/GridRadio';
import { AlignBox } from 'components/organism/AlignBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { useCallback, useEffect, useState } from 'react';
import TextArea from 'components/atom/textarea';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { useLocation } from 'react-router-dom';
import {
  confirmMemberSignUp,
  deleteUserInfoApi,
  getEmailDomainCds,
  getMember,
  getNationPhoneCds,
  getSapCopCds,
  modifyUserInfoApi,
} from 'apis/vars/member/Member';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { MemberVO } from 'models/vars/member/MemberVO';
import GSelectMUIAtomVars from 'components/vars/common/GSelectMUIAtomVars';
import { CommonCode } from 'models/admin/CommonCode';
import { useCommonModal } from 'hooks/useCommonModal';
import _ from 'lodash';
import { ValidationBox } from 'components/validation/ValidationBox';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMemberDetail from './hooks/useMemberDetail';
import GridRadioVars2 from 'components/vars/common/GridRadioVars2';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import VarsAutoInput from 'components/vars/signup/VarsAutoInput';
import { Employee } from 'models/admin/Employee';
import { GCodeSelect } from 'components/selects/GCodeSelect';
import useSessionStore from 'stores/useSessionStore';

interface RespMemberVO {
  vdcpCd: string;
  vdcpErpNm: string;
  vdcpNm: string;
  vdcpMgrNm: string;
  vdcpMgrEmal: string;
  soprMgrCopCd: string;
  soprMgrNm: string;
  mbrPsgStatCd: string;
  dataInsDtm: string;
}

export interface MemberEditVO extends MemberVO {
  cfrmPwd?: string;
  cfrmVdcpCd: boolean;
  vdcpMgrEmalId: string;
  vdcpMgrEmalDmn: string;
  vdcpMgrEmalCstmDmn: string;
  managerInfo: Employee[];

  // LG 에너지 솔루션 담당
  soprMgrNm: string;
  soprMgrEmal: string; // *이메일 등록
  soprMgrPhn: string; // *연락처 등록

  // 영업부서 담당자
  vdcpMgrNm1: string; // 이름
  vdcpMgrEncPhn: string; // 연락처
  vdcpMgrEmal1: string; // *이메일
  vdcpMgrEmal1Id: string;
  vdcpMgrEmal1Dmn: string;
  vdcpMgrEmal1CstmDmn: string;
  vdcpMgrAddEmal?: string; // 추가 이메일
  vdcpMgrAddEmalId?: string;
  vdcpMgrAddEmalDmn?: string;
  vdcpMgrAddEmalCstmDmn?: string;
  vdcpMgrFaxNo?: string;

  // 자금부서 담당(해외)
  vdcpFundMgrNm: string; // 이름
  vdcpFundMgrEncPhn: string; // 연락처
  vdcpFundMgrEmal: string; // 이메일
  vdcpFundMgrEmalId?: string;
  vdcpFundMgrEmalDmn?: string;
  vdcpFundMgrEmalCstmDmn?: string;
  vdcpFundMgrFaxNo?: string;

  dmstFrgDivsCdNm?: string;
  rmkCd?: string;
}

const MemberDetailPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const [option, setOption] = useState('1');
  const { userId, roleCodes } = useSessionStore();

  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const { regSchema, rejectSchema } = useMemberDetail();

  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);

  //TODO 법인 (개발시 공통코드)
  const [coCdList, setCoCdList] = useState<CommonCode[]>([]);
  const [member, setMember] = useState<MemberEditVO>();
  const [validationErrMap, setValidationErrMap] = useState<Map<string, string>>(new Map());
  const navigate = useNavigate();

  // 회원가입
  const [signUpFormData, setSignUpFormData] = useState<MemberEditVO>({
    vdcpCd: '', //공급업체코드_로그인ID
    dmstFrgDivsCd: '', //국내해외구분
    vdcpMgrNm: '', //이름
    soprMgrCopCd: '', //엔솔법인코드
    soprMgrNm: '', //엔솔담당자
    vdcpNm: '', //업체명_직접입력
    vdcpErpNm: '', //업체명_ERP
    pwd: '', //패스워드
    vdcpMgrPhn: '', //연락처
    vdcpMgrEmal: '', //이메일
    idvlIfoClctCncCd: '', //개인정보의 수집 및 이용에 대한안내 동의
    dlvPayAgmnCncCd: '', //납품대금지급에관한약정서 동의
    chtrIdnClctCncCd: '', //고유식별정보와 수집 및 이용동의
    cfrmPwd: '', //패스워드 확인
    cfrmVdcpCd: false,
    vdcpMgrEmalId: '',
    vdcpMgrEmalDmn: '',
    vdcpMgrEmalCstmDmn: '',
    langCd: 'ko',
    managerInfo: [],

    // LG 에너지 솔루션 담당
    soprMgrEmal: '', // *이메일 등록
    soprMgrPhn: '', // *연락처 등록

    // 영업부서 담당자
    vdcpMgrNm1: '', // 이름
    vdcpMgrEncPhn: '', // 연락처
    vdcpMgrEmal1: '', // *이메일
    vdcpMgrEmal1Id: '',
    vdcpMgrEmal1Dmn: '',
    vdcpMgrEmal1CstmDmn: '',
    vdcpMgrAddEmal: '', // 추가 이메일
    vdcpMgrAddEmalId: '',
    vdcpMgrAddEmalDmn: '',
    vdcpMgrAddEmalCstmDmn: '',
    vdcpMgrFaxNo: '',

    // 자금부서 담당(해외)
    vdcpFundMgrNm: '', // 이름
    vdcpFundMgrEncPhn: '', // 연락처
    vdcpFundMgrEmal: '', // 이메일
    vdcpFundMgrEmalId: '',
    vdcpFundMgrEmalDmn: '',
    vdcpFundMgrEmalCstmDmn: '',
    pulAccnRegYn: 'N',
    oseaAccnAddYn: 'N',
    signupReqYn: 'Y',
    vdcpFundMgrFaxNo: '',
    rmkCd: '',

    // 회원가입 반려사유
    reason: '',
    vdcpCtryCd: '',
  });

  // 담당자 검색 팝업
  const [managerSrchModalOpen, setManagerSrchModalOpen] = useState<boolean>(false);
  // 선택된 담당자 리스트
  const [selectedManagerData, setSelectedManagerData] = useState<Employee[]>([]);
  // 선택된 담당자 userId
  const [managerUserId, setManagerUserId] = useState<string>('');

  /**
   * 이메일 생성
   */
  const setEmalAddr = () => {
    const getEmail = (id, dmn) => {
      return id && dmn ? `${id}@${dmn}` : '';
    };
    const tmpVdcpMgrEmal1 = getEmail(signUpFormData.vdcpMgrEmal1Id, signUpFormData.vdcpMgrEmal1Dmn);
    const tmpVdcpMgrAddEmal = getEmail(
      signUpFormData.vdcpMgrAddEmalId,
      signUpFormData.vdcpMgrAddEmalDmn
    );
    const tmpVdcpFundMgrEmal = getEmail(
      signUpFormData.vdcpFundMgrEmalId,
      signUpFormData.vdcpFundMgrEmalDmn
    );

    setSignUpFormData((prev) => ({
      ...prev,
      vdcpMgrEmal1: tmpVdcpMgrEmal1 ?? '',
      vdcpMgrAddEmal: tmpVdcpMgrAddEmal ?? '',
      vdcpFundMgrEmal: tmpVdcpFundMgrEmal ?? '',
    }));
  };

  /**
   * validation
   * @param object
   */
  const checkValidate = async (schema: any, targetData: any) => {
    try {
      setValidationErrMap(new Map());
      await schema.validate(targetData, { abortEarly: false });
      return true;
    } catch (e: any) {
      const errMap = new Map();
      e.inner?.map((err) => {
        errMap.set(err.path, err.message);
      });
      setValidationErrMap(errMap);
      return;
    }
  };

  /**
   * 유효성 체크
   * @returns
   */
  const formValid = () => {
    // 이메일 형식
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emails = [
      signUpFormData.vdcpMgrEmal1,
      signUpFormData.vdcpMgrAddEmal,
      signUpFormData.vdcpFundMgrEmal,
    ];

    const invalidEmails = emails.filter((email) => email && !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      openMessageBar('warning', t('com.label.00230', '__이메일 형식이 올바르지 않습니다.'));
      openLoading(false);
      return false;
    }
    return true;
  };

  useEffect(() => {
    convertVenderInfo(member);
  }, [member]);

  // 이메일 도메인 셀렉트
  const emalKeys = Object.keys(signUpFormData).filter((key) => key.includes('Emal'));
  useEffect(() => {
    setEmalAddr();
  }, [emalKeys.map((key) => signUpFormData[key])]);

  const cmnDataInit = async () => {
    //법인명 가져오기
    await getSapCopCds().then((result: CommonCode[]) => {
      setCoCdList(result);
    });
  };

  /**
   * Init Effect
   */
  useEffect(() => {
    // roleCodes 확인
    console.log('roleCodes ::', roleCodes);
    openLoading(true);
    const vdcpCd = location.state.vdcpCd;
    cmnDataInit().then(() => {
      // 회원 조회
      if (vdcpCd) {
        getMember(vdcpCd)
          .then((data) => {
            if (data) {
              const tmpData = data as MemberEditVO;
              setMember(tmpData);
              convertVenderInfo(tmpData);
              setSelectedManagerData(tmpData.managerInfo ? tmpData.managerInfo : []);
              setManagerUserId(tmpData.managerInfo ? tmpData.managerInfo[0].userId : '');
              console.log('Member Detail Data :::', tmpData);
              openLoading(false);
            }
          })
          .catch(handleError);
      }
    });
  }, []);

  /**
   * 데이터 가공
   * @param pData
   */
  const convertVenderInfo = (pData) => {
    if (!pData) return;

    // 영업부서 담당자 이메일
    pData.vdcpMgrEmal1Id = pData.vdcpMgrEmal1?.split('@')[0];
    pData.vdcpMgrEmal1Dmn = pData.vdcpMgrEmal1?.split('@')[1];

    // 영업부서 담당자 추가이메일
    pData.vdcpMgrAddEmalId = pData.vdcpMgrAddEmal?.split('@')[0];
    pData.vdcpMgrAddEmalDmn = pData.vdcpMgrAddEmal?.split('@')[1];

    // 자금부서 담당자 이메일
    pData.vdcpFundMgrEmalId = pData.vdcpFundMgrEmal?.split('@')[0];
    pData.vdcpFundMgrEmalDmn = pData.vdcpFundMgrEmal?.split('@')[1];

    setSignUpFormData((prev) => {
      return { ...prev, ...pData };
    });
  };

  /**
   * 승인
   * @returns
   */
  const handleClickConfirm = async () => {
    console.log('가입 값 확인 ::: ', signUpFormData);
    try {
      // const content = await editorRef.current?.editorRef.GetBodyValue();
      const validResult = await checkValidate(regSchema, signUpFormData);
      if (!validResult) return;
      // 반려시 반려사유입력 필수
      if (signUpFormData.signupReqYn === 'N') {
        const validRejectResult = await checkValidate(rejectSchema, signUpFormData);
        if (!validRejectResult) return;

        if (signUpFormData.reason?.trim() == '') {
          return;
        }
      }
      openLoading(true);
      // 유효성 체크 (이메일 유효성, 담당자 선택 필수)
      if (formValid() === true && selectedManagerData[0].userId !== undefined) {
        modifyUserInfoApi(signUpFormData).then((res) => {
          if (res === true) {
            confirmMemberSignUp(signUpFormData).then((result) => {
              if (result.successOrNot == 'Y') {
                openLoading(false);
                if (signUpFormData.signupReqYn === 'Y') {
                  openMessageBar('success', t('com.code.approved', '__승인되었습니다.'));
                } else if (signUpFormData.signupReqYn === 'N') {
                  openMessageBar('success', t('com.code.rejected', '__반려되었습니다.'));
                }
                navigate('/system/admin/member/member-list', { replace: true });
              }
            });
          }
        });
      }
    } catch (e: any) {
      openLoading(false);
      return;
    }
  };

  /**
   * 수정
   */
  const handleModify = async () => {
    console.log('수정 ::::', signUpFormData);
    try {
      const validResult = await checkValidate(regSchema, signUpFormData);
      if (!validResult) return;
      openLoading(true);
      if (formValid() === true) {
        modifyUserInfoApi(signUpFormData)
          .then((res) => {
            if (res === true) {
              openLoading(false);
              openMessageBar('success', t('com.label.00173', '__수정되었습니다.'));
              navigate('/system/admin/member/member-list');
            }
          })
          .catch((error) => {
            openLoading(false);
            console.error('Error deleting row:', error);
          });
      }
    } catch (e: any) {
      return;
    }
  };

  // 삭제
  const handleDelete = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.00510', '__회원정보를 삭제하시겠습니까?'),
      noCallback: () => {
        return;
      },
      yesCallback: async () => {
        openLoading(true);
        return deleteUserInfoApi(signUpFormData.userId as string)
          .then((res) => {
            if (res === true) {
              openLoading(false);
              openMessageBar('success', t('com.label.00179', '__삭제되었습니다.'));
              navigate('/system/admin/member/member-list');
            }
          })
          .catch((error) => {
            openLoading(false);
            console.error('Error deleting row:', error);
          });
      },
    });
  };

  /**
   * 인풋 컴포넌트 체인지 이벤트
   * @param e
   */
  const handleChangeForLoginForm = (e) => {
    const numberInputs = [
      'vdcpMgrPhn',
      'vdcpMgrEncPhn',
      'vdcpFundMgrEncPhn',
      'vdcpMgrFaxNo',
      'vdcpFundMgrFaxNo',
    ];

    if (numberInputs.includes(e.target.id)) {
      e.target.value = e.target.value.replace(/[^\d]/g, '', '');
    }

    setSignUpFormData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  return (
    <>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00117', '구분')}</GLabelAtom>
              </th>
              <td>
                {signUpFormData.dmstFrgDivsCd == 'DO'
                  ? t('com.label.00245', '한국')
                  : t('com.label.00248', '한국외')}
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00610', '__법인명')}</GLabelAtom>
              </th>
              <td>
                <GCodeSelect
                  // 관리자화면에서는 법인명 수정가능여부 확인 필요(로직 미정의, 현재 readonly)
                  readonly
                  status={
                    !isSaveClicked
                      ? 'default'
                      : signUpFormData.soprMgrCopCd && !validationErrMap.get('soprMgrCopCd')
                      ? 'default'
                      : 'error'
                  }
                  id={'cmnCd'}
                  labelKey={'cmnCdDesc'}
                  listData={coCdList}
                  value={signUpFormData.soprMgrCopCd}
                  emptyLabel={t('com.label.00023', '__선택') as string}
                  onChange={(e: SelectChangeEvent) => {
                    setSignUpFormData((prev) => {
                      return { ...prev, soprMgrCopCd: e.target.value };
                    });
                  }}
                  width="350px"
                  onlyShowName={true}
                  helperText={
                    isSaveClicked &&
                    !signUpFormData.soprMgrCopCd &&
                    validationErrMap.get('soprMgrCopCd')
                      ? validationErrMap.get('soprMgrCopCd')
                      : undefined
                  }
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">Vendor ID</GLabelAtom>
              </th>
              <td>{signUpFormData.vdcpCd}</td>
              <th>
                <GLabelAtom align="left">{`${t('com.label.00017', '업체명')}(ERP)`}</GLabelAtom>
              </th>
              <td>{signUpFormData.vdcpErpNm}</td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00012', '이름')}</GLabelAtom>
              </th>
              <td>{signUpFormData.vdcpMgrNm}</td>
              <th>
                <GLabelAtom align="left">
                  {`${t('com.label.00017', '업체명')}(${t('com.label.00568', '__거래처입력')})`}
                </GLabelAtom>
              </th>
              <td>
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                  {signUpFormData.vdcpNm}
                </pre>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00091', '__전화번호')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>{signUpFormData.vdcpMgrPhn}</AlignBox>
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                    {signUpFormData.vdcpMgrEmal}
                  </pre>
                </AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content={t('com.label.00015', '__LG에너지솔루션 담당자')}
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00095', '__담당자')}
                </GLabelAtom>
              </th>
              <td>
                <ValidationBox msg={validationErrMap.get('soprMgrNm')}>
                  <VarsAutoInput
                    id="signup"
                    width="350px"
                    openModal={managerSrchModalOpen}
                    setOpenModal={setManagerSrchModalOpen}
                    signUpFormData={signUpFormData}
                    setSignUpFormData={setSignUpFormData}
                    selectedManagerData={selectedManagerData}
                    setSelectedManagerData={setSelectedManagerData}
                    onClick={() => setManagerSrchModalOpen(true)}
                    status={
                      validationErrMap.get('soprMgrNm') && signUpFormData.soprMgrNm.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    disabled={
                      signUpFormData.mbrPsgStatCd === 'C' && roleCodes.every((o) => o === 'PTN')
                    }
                  />
                </ValidationBox>
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    disabled
                    readOnly={true}
                    width="350px"
                    status="disabled"
                    value={
                      selectedManagerData.length > 0 ? selectedManagerData[0].emlSvrDmnIfoNm : ''
                    }
                    // value={
                    //   selectedManagerData.length > 0 && selectedManagerData[0]?.emlSvrDmnIfoNm
                    //     ? selectedManagerData[0].emlSvrDmnIfoNm
                    //     : ''
                    // }
                  />
                </AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content={t('com.label.00089', '__영업부서 담당자')}
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00012', '__이름')}</GLabelAtom>
              </th>
              <td>
                {signUpFormData.mbrPsgStatCd === 'C' && roleCodes.every((o) => o == 'PTN') ? (
                  <>{signUpFormData?.vdcpMgrNm1}</>
                ) : (
                  <InputField
                    id="vdcpMgrNm1"
                    width="350px"
                    placeholder={t2('com.label.00013', '__이름을 입력하세요')}
                    onChange={handleChangeForLoginForm}
                    value={signUpFormData.vdcpMgrNm1}
                    status={
                      validationErrMap.get('vdcpMgrNm1') && signUpFormData.vdcpMgrNm1.trim() === ''
                        ? 'error'
                        : 'readonly'
                    }
                    helperText={
                      validationErrMap.get('vdcpMgrNm1') && signUpFormData.vdcpMgrNm1.trim() === ''
                        ? validationErrMap.get('vdcpMgrNm1')
                        : undefined
                    }
                    maxLength={100}
                    readOnly
                  />
                )}
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00091', '__전화번호')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  {signUpFormData.mbrPsgStatCd === 'C' && roleCodes.every((o) => o == 'PTN') ? (
                    <>{signUpFormData?.vdcpMgrEncPhn}</>
                  ) : (
                    <InputField
                      id="vdcpMgrEncPhn"
                      width="350px"
                      placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                      value={signUpFormData.vdcpMgrEncPhn}
                      status={
                        validationErrMap.get('vdcpMgrEncPhn') &&
                        signUpFormData.vdcpMgrEncPhn.trim() === ''
                          ? 'error'
                          : 'readonly'
                      }
                      helperText={
                        validationErrMap.get('vdcpMgrEncPhn') &&
                        signUpFormData.vdcpMgrEncPhn.trim() === ''
                          ? validationErrMap.get('vdcpMgrEncPhn')
                          : undefined
                      }
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      maxLength={100}
                      readOnly
                    />
                  )}
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
              </th>
              <td>
                <ValidationBox msg={validationErrMap.get('vdcpMgrEmal1')}>
                  <AlignBox>
                    {signUpFormData.mbrPsgStatCd === 'C' && roleCodes.every((o) => o == 'PTN') ? (
                      <>{signUpFormData?.vdcpMgrEmal1}</>
                    ) : (
                      <>
                        <InputField
                          id="vdcpMgrEmal1Id"
                          width="165px"
                          status="readonly"
                          placeholder={t2('com.label.00025', '__이메일아이디')}
                          onChange={(e) => {
                            handleChangeForLoginForm(e);
                          }}
                          value={signUpFormData.vdcpMgrEmal1Id}
                          maxLength={100}
                          readOnly
                        />
                        @
                        <InputField
                          id="vdcpMgrEmal1Dmn"
                          status="readonly"
                          placeholder={t2('com.label.00026', '__직접입력')}
                          value={signUpFormData.vdcpMgrEmal1Dmn}
                          width="165px"
                          onChange={(e) => {
                            handleChangeForLoginForm(e);
                          }}
                          maxLength={100}
                          readOnly
                        />
                      </>
                    )}
                  </AlignBox>
                </ValidationBox>
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00665', '__팩스번호')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    id="vdcpMgrFaxNo"
                    width="350px"
                    status="readonly"
                    placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    value={signUpFormData.vdcpMgrFaxNo}
                    maxLength={15}
                    readOnly
                  />
                </AlignBox>
              </td>
              {/* <th>
                <GLabelAtom align="left">{t('com.label.00092', '__추가 이메일')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  {signUpFormData.mbrPsgStatCd === 'C' && roleCodes[0] == 'PTN' ? (
                    <>{signUpFormData?.vdcpMgrAddEmal}</>
                  ) : (
                    <>
                      <InputField
                        id="vdcpMgrAddEmalId"
                        width="165px"
                        status="readonly"
                        placeholder={t2('com.label.00025', '__이메일아이디')}
                        onChange={(e) => {
                          handleChangeForLoginForm(e);
                        }}
                        value={signUpFormData.vdcpMgrAddEmalId}
                        maxLength={100}
                        readOnly
                      />
                      @
                      <InputField
                        id="vdcpMgrAddEmalDmn"
                        status="readonly"
                        placeholder={t2('com.label.00026', '__직접입력')}
                        value={signUpFormData.vdcpMgrAddEmalDmn}
                        width="165px"
                        onChange={(e) => {
                          handleChangeForLoginForm(e);
                        }}
                        maxLength={100}
                        readOnly
                      />
                    </>
                  )}
                </AlignBox>
              </td> */}
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content={t('com.label.00093', '__자금부서 담당자')}
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true} style={{ marginBottom: '0px' }}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00012', '__이름')}</GLabelAtom>
              </th>
              <td>
                {signUpFormData.mbrPsgStatCd === 'C' && roleCodes.every((o) => o == 'PTN') ? (
                  <>{signUpFormData?.vdcpFundMgrNm}</>
                ) : (
                  <InputField
                    id="vdcpFundMgrNm"
                    width="350px"
                    placeholder={t2('com.label.00013', '__이름을 입력하세요')}
                    onChange={handleChangeForLoginForm}
                    value={signUpFormData.vdcpFundMgrNm}
                    status={
                      validationErrMap.get('vdcpFundMgrNm') &&
                      signUpFormData.vdcpFundMgrNm.trim() === ''
                        ? 'error'
                        : 'readonly'
                    }
                    helperText={
                      validationErrMap.get('vdcpFundMgrNm') &&
                      signUpFormData.vdcpFundMgrNm.trim() === ''
                        ? validationErrMap.get('vdcpFundMgrNm')
                        : undefined
                    }
                    maxLength={100}
                    readOnly
                  />
                )}
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00091', '__전화번호')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  {signUpFormData.mbrPsgStatCd === 'C' && roleCodes.every((o) => o == 'PTN') ? (
                    <>{signUpFormData?.vdcpFundMgrEncPhn}</>
                  ) : (
                    <InputField
                      id="vdcpFundMgrEncPhn"
                      width="350px"
                      placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                      status={
                        validationErrMap.get('vdcpFundMgrEncPhn') &&
                        signUpFormData.vdcpFundMgrEncPhn.trim() === ''
                          ? 'error'
                          : 'readonly'
                      }
                      helperText={
                        validationErrMap.get('vdcpFundMgrEncPhn') &&
                        signUpFormData.vdcpFundMgrEncPhn.trim() === ''
                          ? validationErrMap.get('vdcpFundMgrEncPhn')
                          : undefined
                      }
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      value={signUpFormData.vdcpFundMgrEncPhn}
                      maxLength={100}
                      readOnly
                    />
                  )}
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
              </th>
              <td>
                <ValidationBox msg={validationErrMap.get('vdcpFundMgrEmal')}>
                  <AlignBox>
                    {signUpFormData.mbrPsgStatCd === 'C' && roleCodes.every((o) => o == 'PTN') ? (
                      <>{signUpFormData?.vdcpFundMgrEmal}</>
                    ) : (
                      <>
                        <InputField
                          id="vdcpFundMgrEmalId"
                          width="165px"
                          status="readonly"
                          placeholder={t2('com.label.00025', '__이메일아이디')}
                          onChange={(e) => {
                            handleChangeForLoginForm(e);
                          }}
                          value={signUpFormData.vdcpFundMgrEmalId}
                          maxLength={100}
                          readOnly
                        />
                        @
                        <InputField
                          id="vdcpFundMgrEmalDmn"
                          status="readonly"
                          placeholder={t2('com.label.00026', '__직접입력')}
                          value={signUpFormData.vdcpFundMgrEmalDmn}
                          width="165px"
                          onChange={(e) => {
                            handleChangeForLoginForm(e);
                          }}
                          maxLength={100}
                          readOnly
                        />
                      </>
                    )}
                  </AlignBox>
                </ValidationBox>
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00665', '__팩스번호')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    id="vdcpFundMgrFaxNo"
                    width="350px"
                    status="readonly"
                    placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    value={signUpFormData.vdcpFundMgrFaxNo}
                    maxLength={15}
                    readOnly
                  />
                </AlignBox>
              </td>
            </tr>
            {signUpFormData.rmkCd && (
              <tr>
                <th>
                  <GLabelAtom align="left">{t('com.label.rmk', '__비고')}</GLabelAtom>
                </th>
                <td>
                  <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                    {signUpFormData.rmkCd}
                  </pre>
                </td>
              </tr>
            )}
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      {/* roleCodes가 PTN(현업담당자)인 경우 복수계좌 선택란 숨김 */}
      {!roleCodes.every((o) => o == 'PTN') && signUpFormData.soprMgrCopCd === 'C100' && (
        <>
          <SubTitleBtnArea style={{ marginTop: '12px' }}>
            <SubTitleBox
              content={t('com.label.00611', '__원화복수계좌 등록여부')}
              showSubTitle={true}
              isVisibleComment={false}
              isVisibleInfo={false}
            />
          </SubTitleBtnArea>
          <GbasicTableWrap isBottomMgn={true} style={{ marginBottom: '0px' }}>
            <GbasicTable>
              <colgroup>
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <GLabelAtom align="left">{t('com.label.00117', '구분')}</GLabelAtom>
                  </th>
                  <td colSpan={3}>
                    <GridRadioVars2
                      id="pulAccnRegYn"
                      valueKey="cmnCd"
                      labelKey="cmnCdNm"
                      option={[
                        { cmnCd: 'Y', cmnCdNm: t('com.label.00606', '__등록가능') },
                        { cmnCd: 'N', cmnCdNm: t('com.label.00607', '__등록불가') },
                      ]}
                      value={signUpFormData.pulAccnRegYn ?? 'N'}
                      onChange={(e) => {
                        setSignUpFormData((prev) => {
                          return { ...prev, pulAccnRegYn: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </GbasicTable>
          </GbasicTableWrap>
        </>
      )}

      {!roleCodes.every((o) => o == 'PTN') && signUpFormData.soprMgrCopCd !== 'C100' && (
        <>
          <SubTitleBtnArea style={{ marginTop: '12px' }}>
            <SubTitleBox
              content={t('com.code.regStatMulAccn', '__외화복수계좌 등록여부')}
              showSubTitle={true}
              isVisibleComment={false}
              isVisibleInfo={false}
            />
          </SubTitleBtnArea>
          <GbasicTableWrap isBottomMgn={true} style={{ marginBottom: '0px' }}>
            <GbasicTable>
              <colgroup>
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <GLabelAtom align="left">{t('com.label.00117', '구분')}</GLabelAtom>
                  </th>
                  <td colSpan={3}>
                    <GridRadioVars2
                      id="oseaAccnAddYn"
                      valueKey="cmnCd"
                      labelKey="cmnCdNm"
                      option={[
                        { cmnCd: 'Y', cmnCdNm: t('com.label.00606', '__등록가능') },
                        { cmnCd: 'N', cmnCdNm: t('com.label.00607', '__등록불가') },
                      ]}
                      value={signUpFormData.oseaAccnAddYn ?? 'N'}
                      onChange={(e) => {
                        setSignUpFormData((prev) => {
                          return { ...prev, oseaAccnAddYn: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </GbasicTable>
          </GbasicTableWrap>
        </>
      )}

      {/* 회원가입 상태가 '처리중'일때만 보임 */}
      {signUpFormData.mbrPsgStatCd === 'R' && (
        <>
          <SubTitleBtnArea style={{ marginTop: '12px' }}>
            <SubTitleBox
              content={t('com.code.memRegPrc', '__회원가입 처리')}
              showSubTitle={true}
              isVisibleComment={false}
              isVisibleInfo={false}
            />
          </SubTitleBtnArea>
          <GbasicTableWrap isBottomMgn={true} style={{ marginBottom: '0px' }}>
            <GbasicTable>
              <colgroup>
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <GLabelAtom align="left">{t('com.label.00117', '구분')}</GLabelAtom>
                  </th>
                  <td>
                    <GridRadioVars2
                      id="signupReqYn"
                      valueKey="cmnCd"
                      labelKey="cmnCdNm"
                      option={[
                        { cmnCd: 'Y', cmnCdNm: t('com.label.00260', '__승인') },
                        { cmnCd: 'N', cmnCdNm: t('com.code.PROG_STAT_CD.R', '__반려') },
                      ]}
                      value={signUpFormData.signupReqYn ?? 'Y'}
                      onChange={(e) => {
                        setSignUpFormData((prev) => {
                          return { ...prev, signupReqYn: e.target.value };
                        });
                      }}
                    />
                  </td>
                  <th>
                    <GLabelAtom
                      align="left"
                      isRequired={signUpFormData.signupReqYn === 'N' ? true : false}
                    >
                      {t('com.label.00668', '반려사유')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="reason"
                      readOnly={signUpFormData.signupReqYn === 'N' ? false : true}
                      status={
                        signUpFormData.signupReqYn === 'N'
                          ? validationErrMap.get('reason') && signUpFormData.reason?.trim() === ''
                            ? 'error'
                            : 'default'
                          : 'readonly'
                      }
                      placeholder={
                        signUpFormData.signupReqYn === 'N'
                          ? t2('com.label.00669', '__반려사유를 입력해주세요.')
                          : ''
                      }
                      value={signUpFormData.reason}
                      width="350px"
                      helperText={
                        signUpFormData.signupReqYn === 'N' &&
                        validationErrMap.get('reason') &&
                        signUpFormData.reason?.trim() === ''
                          ? validationErrMap.get('reason')
                          : undefined
                      }
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </GbasicTable>
          </GbasicTableWrap>
        </>
      )}

      <GButtonLayout>
        <GButtonBox>
          {/* 처리상태가 '승인완료'이고 PTN(현업담당자)이 아닌 경우에 수정, 삭제버튼 노출 */}
          {signUpFormData.mbrPsgStatCd === 'C' && !roleCodes.every((o) => o == 'PTN') && (
            <>
              <GButton
                chkImg={Delete}
                txt={t('com.label.00054', '삭제')}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
                onClick={handleDelete}
              ></GButton>
              <GButton
                txt={t('com.label.00209', '수정')}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={false}
                hasTxt={true}
                onClick={handleModify}
              ></GButton>
            </>
          )}
          <GButton
            txt={t('com.btn.cancel', '__취소')}
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
            onClick={() => navigate('/system/admin/member/member-list')}
          ></GButton>
          {/* 처리상태가 '처리중'일 때만 회원정보저장 버튼 보임 */}
          {signUpFormData.mbrPsgStatCd === 'R' && (
            <GButton
              chkImg={Save}
              txt={t('com.msg.saveMemberInfo', '__회원정보저장')}
              sizes="medium"
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
              onClick={handleClickConfirm}
            />
          )}
        </GButtonBox>
      </GButtonLayout>
    </>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 275px;
`;

const ContentItem = styled(ContentWrap)`
  margin-top: 12px;
`;

export default MemberDetailPage;
