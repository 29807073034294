/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { BlueButton, IconButton } from 'components/buttons/CustomButton';
import CrossEditor from 'components/crosseditor/CrossEditor';
import { st } from 'components/inputs/CustomInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox } from '@mui/material';
import { ContentSection } from 'components/layouts/ContentSection';
import { di } from 'components/layouts/Dialog';

interface NoticeModalProps {
  bbmNo: string;
  bbmTitNm: string;
  bbmCtn: string;
  poupEpsNuseDdn: number;
}

export const NoticeModal = ({ bbmNo, bbmTitNm, bbmCtn, poupEpsNuseDdn }: NoticeModalProps) => {
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);
  const [appCookies, setAppCookies] = useCookies();
  const editorRef = useRef<CrossEditor>(null);

  const handleClose = () => {
    if (checked) {
      closeModalUntilExpires();
    }
    setOpen(false);
  };

  const handleEditerOnLoad = () => {
    editorRef.current?.editorRef.SetReadonly(true);
    editorRef.current?.editorRef.SetBodyValue(bbmCtn);
  };

  const getExpiredDate = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  const closeModalUntilExpires = () => {
    if (!appCookies) return;

    const expires = getExpiredDate(poupEpsNuseDdn);
    setAppCookies('NOTICE_EXPIRES_' + bbmNo, true, { path: '/', expires });
  };

  useEffect(() => {
    if (appCookies['NOTICE_EXPIRES_' + bbmNo]) {
      setOpen(false);
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} css={di.dialog} fullWidth maxWidth="xs">
      <DialogTitle className="popupTitle">
        {bbmTitNm}
        <IconButton className="buttonClose" onClick={handleClose}>
          <CloseIcon fontSize="large"></CloseIcon>
        </IconButton>
      </DialogTitle>
      <DialogContent className="popupContent">
        <ContentSection className="marginT0">
          <CrossEditor
            ref={editorRef}
            name={`crosseditor` + bbmNo}
            params={{
              Width: '100%',
              Height: 400,
              Chevron: true,
              Readonly: true,
            }}
            onLoaded={handleEditerOnLoad}
          />
        </ContentSection>
      </DialogContent>
      <DialogActions className="popupBottom">
        <Checkbox
          onChange={() => setChecked(!checked)}
          css={st.checkbox}
          checked={checked}
          value={checked}
        />
        <p>{poupEpsNuseDdn}일 동안 보지 않기</p>
        <BlueButton onClick={handleClose}>확인</BlueButton>
      </DialogActions>
    </Dialog>
  );
};
