import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GLabelAtom } from 'components/atom/label';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import InputField from 'components/atom/input';
import { GButton } from 'components/atom/button';
import SubTitleBox from 'components/molecule/SubTitleBox';
import GSelectMUIAtom from 'components/common/select/GSelectMUIAtom';
import { RadioWrap } from 'components/molecule/GridRadio';
import { AlignBox } from 'components/organism/AlignBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import Save from 'components/asset/images/Confirm.svg';
import styled from '@emotion/styled';
import { MemberVO } from 'models/vars/member/MemberVO';
import { LoginInfo } from 'models/vars/login/LoginInfo';
import { CrudCode } from 'models/common/Edit';
import { CommonCode } from 'models/admin/CommonCode';
import {
  checkVendorApi,
  getSapCopCds,
  signUpMember,
  validSignUpJointCertificate,
} from 'apis/vars/member/Member';
import useMemberRegist from './hooks/useMemberRegist';
import { useCommonModal } from 'hooks/useCommonModal';
import { ValidationBox } from 'components/validation/ValidationBox';
import { getCommonCodes } from 'apis/admin/CommonCode';
import { useCommonCert } from 'hooks/useCommonCert';
import _ from 'lodash';
import VarsAutoInput from 'components/vars/signup/VarsAutoInput';
import { Employee } from 'models/admin/Employee';
import GridRadioVars2 from 'components/vars/common/GridRadioVars2';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import { useMessageBar } from 'components/process/MessageBar';
import { useLoading } from 'components/process/Loading';
import { PrivacyAgreeKo } from 'components/vars/signup/PrivacyAgreeKo';
import useSessionStore from 'stores/useSessionStore';
import PrivacyAgreeEn from 'components/vars/signup/PrivacyAgreeEn';
import PrivacyAgreeZhc from 'components/vars/signup/PrivacyAgreeZhc';
import DeliveryAgreeKo from 'components/vars/signup/DeliveryAgreeKo';
import DeliveryAgreeEn from 'components/vars/signup/DeliveryAgreeEn';
import DeliveryAgreeZhc from 'components/vars/signup/DeliveryAgreeZhc';
import useAuthStore from 'stores/useAuthStore';
import { EggTwoTone } from '@mui/icons-material';

export interface MemberRegVO extends MemberVO {
  cfrmPwd: string;
  cfrmVdcpCd: boolean;
  vdcpMgrEmalId: string;
  vdcpMgrEmalDmn: string;
  vdcpMgrEmalCstmDmn: string;

  // LG 에너지 솔루션 담당
  soprMgrNm: string;
  soprMgrEmal: string; // *이메일
  soprMgrEmpNo: string; // 사번

  // 영업부서 담당자
  vdcpMgrNm1: string; // 이름
  vdcpMgrEncPhn: string; // 연락처
  vdcpMgrEmal1: string; // *이메일
  vdcpMgrEmal1Id: string;
  vdcpMgrEmal1Dmn: string;
  vdcpMgrEmal1CstmDmn: string;
  vdcpMgrAddEmal?: string; // 추가 이메일
  vdcpMgrAddEmalId?: string;
  vdcpMgrAddEmalDmn?: string;
  vdcpMgrAddEmalCstmDmn?: string;
  vdcpMgrFaxNo?: string;

  // 자금부서 담당(해외)
  vdcpFundMgrNm: string; // 이름
  vdcpFundMgrEncPhn: string; // 연락처
  vdcpFundMgrEmal: string; // 이메일
  vdcpFundMgrEmalId?: string;
  vdcpFundMgrEmalDmn?: string;
  vdcpFundMgrEmalCstmDmn?: string;
  vdcpFundMgrFaxNo?: string;
  rmkCd?: string; // 영업부서 담당자, 자금부서 담당자명 일치 혹은 도메인 주소 다를 시 비고작성

  bzno?: string;
  bznoKey?: string;
  cfrmPwdAll?: boolean;
  pwdValid?: boolean;
}

const MemberRegistPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const { langCd } = useSessionStore();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const [validationErrMap, setValidationErrMap] = useState<Map<string, string>>(new Map());
  const { openCommonModal } = useCommonModal();
  const { callCertModule } = useCommonCert();
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
  const { krRegSchema, ovRegSchema } = useMemberRegist();
  // 회원가입
  const [signUpFormData, setSignUpFormData] = useState<MemberRegVO>({
    vdcpCd: '', //공급업체코드_로그인ID
    dmstFrgDivsCd: 'DO', //국내해외구분
    vdcpMgrNm: '', //이름
    soprMgrCopCd: '', //엔솔법인코드
    soprMgrNm: '', //엔솔담당자
    vdcpNm: '', //업체명_직접입력
    vdcpErpNm: '', //업체명_ERP
    pwd: '', //패스워드
    vdcpMgrPhn: '', //연락처
    vdcpMgrEmal: '', //이메일
    idvlIfoClctCncCd: 'N', //개인정보의 수집 및 이용에 대한안내 동의
    dlvPayAgmnCncCd: 'N', //납품대금지급에관한약정서 동의
    chtrIdnClctCncCd: 'Y', //고유식별정보와 수집 및 이용동의
    cfrmPwd: '', //패스워드 확인
    cfrmVdcpCd: false,
    vdcpMgrEmalId: '',
    vdcpMgrEmalDmn: '',
    vdcpMgrEmalCstmDmn: '',
    publCertKey: '',
    dgsVal: '',
    bzno: '',
    bznoKey: '',
    langCd: 'ko',
    crudKey: CrudCode.CREATE,

    // LG 에너지 솔루션 담당
    soprMgrEmal: '', // *이메일 등록
    soprMgrEmpNo: '', // 사번

    // 영업부서 담당자
    vdcpMgrNm1: '', // 이름
    vdcpMgrEncPhn: '', // 연락처
    vdcpMgrEmal1: '', // *이메일
    vdcpMgrEmal1Id: '',
    vdcpMgrEmal1Dmn: '',
    vdcpMgrEmal1CstmDmn: '',
    vdcpMgrAddEmal: '', // 추가 이메일
    vdcpMgrAddEmalId: '',
    vdcpMgrAddEmalDmn: '',
    vdcpMgrAddEmalCstmDmn: '',
    vdcpMgrFaxNo: '',

    // 자금부서 담당(해외)
    vdcpFundMgrNm: '', // 이름
    vdcpFundMgrEncPhn: '', // 연락처
    vdcpFundMgrEmal: '', // 이메일
    vdcpFundMgrEmalId: '',
    vdcpFundMgrEmalDmn: '',
    vdcpFundMgrEmalCstmDmn: '',
    vdcpFundMgrFaxNo: '',
    rmkCd: '',

    cfrmPwdAll: false,
    pwdValid: false,
    vdcpCtryCd: '',
  });

  //TODO 법인 (개발시 공통코드)
  const [coCdList, setCoCdList] = useState<CommonCode[]>([]);
  // 담당자 검색 팝업
  const [managerSrchModalOpen, setManagerSrchModalOpen] = useState<boolean>(false);
  // 선택된 담당자 리스트
  const [selectedManagerData, setSelectedManagerData] = useState<Employee[]>([]);
  // 한국/ 한국외
  const [dmstFrgOptionList, setDmstFrgOptionList] = useState<CommonCode[]>([]);

  // 비고란 생성
  const [showRmk, setShowRmk] = useState<boolean>(false);

  /**
   * 국내/ 해외 여부 Radio 체인지 이벤트
   * @param e
   */
  const handleChangeFrgDivsCd = (e) => {
    setSignUpFormData((prev) => {
      return { ...prev, dmstFrgDivsCd: e.target.value };
    });
  };

  /**
   * 인풋 컴포넌트 체인지 이벤트
   * @param e
   */
  const handleChangeForLoginForm = (e) => {
    const numberInputs = [
      'vdcpMgrPhn',
      'vdcpMgrEncPhn',
      'vdcpFundMgrEncPhn',
      'vdcpMgrFaxNo',
      'vdcpFundMgrFaxNo',
    ];

    if (numberInputs.includes(e.target.id)) {
      e.target.value = e.target.value.replace(/[^\d]/g, '', '');
    }

    isValidPasswordLen(e);

    setSignUpFormData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const isValidPasswordLen = (e) => {
    //패스워드 15자리 넘었을 때 이벤트
    if (e.target.id === 'pwd' && e.target.value && e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);

      validationErrMap.set(
        'pwdLenOver',
        t(
          'com.label.00662',
          '__패스워드는 최소 10자, 최대 15자로 영문자 및 숫자로 구성되어야 합니다.'
        )
      );
      setValidationErrMap(validationErrMap);
    } else if (validationErrMap.get('pwdLenOver') && e.target.value.length < 15) {
      validationErrMap.set('pwdLenOver', '');
      setValidationErrMap(validationErrMap);
    }
  };

  // Vendor ID 대문자로 입력받기
  const handleChangeVendorId = (e) => {
    const upperVendorId = e.target.value.trim().toUpperCase();
    setSignUpFormData((prev) => {
      return { ...prev, vdcpCd: upperVendorId };
    });
  };

  // 법인명 C100일 경우 DO 그 외 OV
  const handleChangeCopCd = (e) => {
    const selectedCopCd = e.target.value;
    if (selectedCopCd === 'C100') {
      setSignUpFormData((prev) => {
        return { ...prev, dmstFrgDivsCd: 'DO', soprMgrCopCd: selectedCopCd };
      });
    } else {
      setSignUpFormData((prev) => {
        return { ...prev, dmstFrgDivsCd: 'OV', soprMgrCopCd: selectedCopCd };
      });
    }
  };

  /**
   * validation
   * @param object
   */
  const checkValidate = async (schema: any, targetData: any) => {
    try {
      setValidationErrMap(new Map());
      await schema.validate(targetData, { abortEarly: false });
      return true;
    } catch (e: any) {
      const errMap = new Map();
      e.inner?.map((err) => {
        errMap.set(err.path, err.message);
      });
      setValidationErrMap(errMap);
      return;
    }
  };

  // 패스워드 입력값 규칙
  const isValidPassword = (password: string): boolean => {
    // 입력값이 10자리 이상 15자리 이하인지 확인
    if (password.length < 10 || password.length > 15) {
      return false;
    }
    // 입력값이 문자, 숫자, 특수기호포함 가능(세자리 이상 연속된 문자 불가능)
    const alphanumericRegex =
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?!.*(.)\1{2})(?!.*(.\d)\2{2})[a-zA-Z0-9!@#$%^&*()-_=+[\]{};:'",.<>/?]*$/;
    if (!alphanumericRegex.test(password)) {
      return false;
    }
    return true;
  };

  /**
   * 이메일 생성
   */
  const setEmalAddr = () => {
    const getEmail = (id, dmn) => {
      return id && dmn ? `${id}@${dmn}` : '';
    };

    const tmpVdcpMgrEmal = getEmail(signUpFormData.vdcpMgrEmalId, signUpFormData.vdcpMgrEmalDmn);
    const tmpVdcpMgrEmal1 = getEmail(signUpFormData.vdcpMgrEmal1Id, signUpFormData.vdcpMgrEmal1Dmn);
    const tmpVdcpMgrAddEmal = getEmail(
      signUpFormData.vdcpMgrAddEmalId,
      signUpFormData.vdcpMgrAddEmalDmn
    );
    const tmpVdcpFundMgrEmal = getEmail(
      signUpFormData.vdcpFundMgrEmalId,
      signUpFormData.vdcpFundMgrEmalDmn
    );

    setSignUpFormData((prev) => ({
      ...prev,
      vdcpMgrEmal: tmpVdcpMgrEmal ?? '',
      vdcpMgrEmal1: tmpVdcpMgrEmal1 ?? '',
      vdcpMgrAddEmal: tmpVdcpMgrAddEmal ?? '',
      vdcpFundMgrEmal: tmpVdcpFundMgrEmal ?? '',
    }));
  };

  /**
   * VendorID 인증 (SAP_IF)
   */
  const validateVenderId = async () => {
    //TODO 임시 패스
    if (!signUpFormData.vdcpCd) {
      openMessageBar('warning', t('com.label.00016', 'Vendor ID를 입력하세요'));
      return;
    }
    if (!signUpFormData.soprMgrCopCd) {
      // openMessageBar('warning', t('com.label.00612', '__법인명을 선택하세요'));
      openCommonModal({
        content: t('com.label.00651', '__법인명을 먼저 선택하세요'),
      });
      return;
    }
    openLoading(true);
    checkVendorApi(
      signUpFormData.vdcpCd,
      signUpFormData.soprMgrCopCd,
      signUpFormData.dmstFrgDivsCd
    ).then((res) => {
      if (res.successOrNot === 'Y') {
        openLoading(false);
        // 중복 가입자(가입진행중 / 가입완료 분기처리) >> 인증 실패
        if (res.data.userId != (undefined || null)) {
          if (res.data.mbrPsgStatCd === 'C') {
            return openMessageBar('warning', t('com.label.00531', '이미 가입된 Vendor ID입니다.'));
          } else if (res.data.mbrPsgStatCd === 'R') {
            return openMessageBar(
              'warning',
              t('com.label.00498', '회원가입승인 처리중입니다. 승인 처리시 이메일이 발송됩니다.')
            );
          }
        }
        if (res.data.sapValidYn == 'N') {
          // 0401 3차검수 피드백(메세지바 > 모달)
          return openCommonModal({
            content: t(
              'com.label.00602',
              '__당사 업무 담당자를 통해 업체코드가 부여됐는지 확인하세요.'
            ),
          });
          // return openMessageBar(
          //   'warning',
          //   t('com.label.00602', '__당사 업무 담당자를 통해 업체코드가 부여됐는지 확인하세요.')
          // );
        }
        openMessageBar('success', t('com.label.00040', '인증에 성공하였습니다.'));
        return setSignUpFormData({
          ...signUpFormData,
          cfrmVdcpCd: true,
          vdcpCtryCd: res.data.vdcpCtryCd,
          bznoKey: res.data.bznoKey,
        });
      } else {
        openLoading(false);
        return openMessageBar(
          'warning',
          t('com.label.00602', '__당사 업무 담당자를 통해 업체코드가 부여됐는지 확인하세요.')
        );
      }
    });
  };

  /**
   * 유효성 체크
   * @returns
   */
  const formValid = () => {
    // 이메일 형식
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emails = [
      signUpFormData.vdcpMgrEmal,
      signUpFormData.vdcpMgrEmal1,
      signUpFormData.vdcpMgrAddEmal,
      signUpFormData.vdcpFundMgrEmal,
    ];

    const invalidEmails = emails.filter((email) => email && !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      openMessageBar('warning', t('com.label.00230', '__이메일 형식이 올바르지 않습니다.'));
      openLoading(false);
      return false;
    }
    return true;
  };

  // 패스워드 확인
  useEffect(() => {
    // if (isValidPassword(signUpFormData.pwd) === true) {
    //   setSignUpFormData({
    //     ...signUpFormData,
    //     pwdValid: true,
    //   });
    // }
    setSignUpFormData({
      ...signUpFormData,
      pwdValid: isValidPassword(signUpFormData.pwd),
      cfrmPwdAll: signUpFormData.cfrmPwd.trim() === signUpFormData.pwd,
    });
  }, [signUpFormData.pwd, signUpFormData.cfrmPwd]);

  /**
   * 가입 버튼 이벤트
   */
  const handleClickSignUp = async () => {
    try {
      // const content = await editorRef.current?.editorRef.GetBodyValue();
      // 공동인증 여부 확인
      if (signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR')) {
        if (!signUpFormData.cfrmVdcpCd) {
          openCommonModal({
            content: t('com.label.00559', '__Vendor ID 확인이 필요합니다.'),
          });
          return;
        }
        if (!signUpFormData.publCertKey) {
          // openMessageBar('warning', t('com.label.00236', '공동인증서를 등록하세요'));
          openCommonModal({
            content: t('com.label.00236', '공동인증서를 등록하세요'),
          });
          return;
        }
      }
      setIsSaveClicked(true);
      if (signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR')) {
        const validKrResult = await checkValidate(krRegSchema, signUpFormData);
        console.log('체크 ', validKrResult);
        if (!validKrResult) return;
      } else if (!signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR')) {
        const validOvResult = await checkValidate(ovRegSchema, signUpFormData);
        if (!validOvResult) return;
      }
      const checkTrue =
        signUpFormData.chtrIdnClctCncCd === 'Y' &&
        signUpFormData.dlvPayAgmnCncCd === 'Y' &&
        signUpFormData.idvlIfoClctCncCd === 'Y';

      // 유효성 체크 (이메일 유효성, 비밀번호 비교)
      if (formValid() === true && checkTrue) {
        // 가입 클릭 시 아래 1, 2, 3 확인하여 자금부서 담당자 '비고'란 입력받기
        // 1. 영업부서 담당자 이름과 자금부서 담당자 이름이 같거나
        // 2. 영업부서 담당자 도메인과 자금부서 담당자 도메인이 다를경우
        // 0403 추가 -- 3. 영업부서 담당자와 자금부서 담당자 이메일이 동일한 경우
        if (
          (signUpFormData.vdcpMgrNm1 === signUpFormData.vdcpFundMgrNm ||
            signUpFormData.vdcpMgrEmal1Dmn !== signUpFormData.vdcpFundMgrEmalDmn ||
            signUpFormData.vdcpMgrEmal1 === signUpFormData.vdcpFundMgrEmal) &&
          signUpFormData.rmkCd?.trim() == ''
        ) {
          setShowRmk(true);
          openCommonModal({
            modalType: 'alert',
            content: (
              <>
                {t(
                  'com.label.00663',
                  '__구매처 영업 담당자와 자금부서 담당자 정보가 일치하거나, 이메일 도메인 주소가 상이합니다.'
                )}
                <br />
                {t(
                  'com.label.00664',
                  '__비고란에 사유 소명 및 계좌등록 시 증빙 첨부 부탁드립니다.'
                )}
              </>
            ),
          });
        } else {
          openLoading(true);
          signUpMember(signUpFormData)
            .then((result) => {
              if (result.successOrNot == 'Y') {
                openLoading(false);
                openCommonModal({
                  modalType: 'alert',
                  content: (
                    <>
                      {t('com.label.00603', '__회원가입이 승인 대기중입니다.')}
                      <br />
                      {t(
                        'com.label.comfirmEmail',
                        '__가입이 승인되면 입력하신 이메일로 확인메일이 발송됩니다.'
                      )}
                    </>
                  ),
                });

                useAuthStore.setState({ accessToken: '' });
                useSessionStore.getState().resetSession();
                sessionStorage.clear();

                navigate('/', { replace: true });
              } else {
                openLoading(false);
              }
            })
            .catch((e) => {
              console.error(e);
              openLoading(false);
            })
            .finally(() => {
              openLoading(false);
            });
        }
      } else if (formValid() === true && !checkTrue) {
        openMessageBar('warning', t('com.label.00604', '__하기 내용에 대해 동의해주세요.'));
        return false;
      }
    } catch (e: any) {
      return;
    }
  };

  // 이메일 도메인 셀렉트
  const emalKeys = Object.keys(signUpFormData).filter((key) => key.includes('Emal'));
  useEffect(() => {
    setEmalAddr();
  }, [emalKeys.map((key) => signUpFormData[key])]);

  const getDmstFrgDivCds = useCallback(() => {
    getCommonCodes('DMST_FRG_DIVS_CD').then((result: CommonCode[]) => {
      setDmstFrgOptionList(result);
    });
  }, []);

  useEffect(() => {
    if (signUpFormData.publCertKey) {
      const tempParam = {
        ...signUpFormData,
      } as LoginInfo;

      validSignUpJointCertificate(tempParam).then((resp) => {
        if (resp) {
          openMessageBar('success', t('com.label.00718', '__인증서 등록이 완료되었습니다.'));
        } else {
          setSignUpFormData((prev) => {
            return {
              ...prev,
              publCertKey: '',
              dgsVal: '',
            };
          });
          openCommonModal({
            content: t('com.label.00716', '유효하지 않은 인증서입니다.'),
          });
        }
      });
    }
  }, [signUpFormData.publCertKey]);

  useEffect(() => {
    //법인명 가져오기
    getSapCopCds().then((result: CommonCode[]) => {
      setCoCdList(result);
    });
    // 한국, 한국외 가져오기
    getDmstFrgDivCds();
  }, []);

  return (
    <ScreenBox>
      <Scrollable>
        <GButtonLayout>
          <GButtonBox style={{ marginBottom: '8px' }}>
            <GButton
              txt={t('com.label.00036', '취소')}
              sizes="medium"
              onClick={() => navigate('/')}
              btnstyled="outline"
              btnColor="normal"
              hasImg={false}
              hasTxt={true}
            />
            <GButton
              chkImg={Save}
              txt={t('com.label.00032', '__가입')}
              sizes="medium"
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
              onClick={handleClickSignUp}
            />
          </GButtonBox>
        </GButtonLayout>
        <GbasicTableWrap isBottomMgn={true}>
          <GbasicTable>
            <colgroup>
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00610', '__법인명')}
                  </GLabelAtom>
                </th>
                <td>
                  <GSelectMUIAtom
                    status={
                      !isSaveClicked
                        ? 'default'
                        : !signUpFormData.soprMgrCopCd && validationErrMap.get('soprMgrCopCd')
                        ? 'error'
                        : 'default'
                    }
                    selectWidth="350px"
                    option={coCdList}
                    placeholder={t('com.label.00023', '__선택') as string}
                    codeKey="cmnCd"
                    codeName="cmnCdDesc"
                    value={signUpFormData.soprMgrCopCd || ''}
                    handleChange={handleChangeCopCd}
                    helperText={
                      isSaveClicked &&
                      !signUpFormData.soprMgrCopCd &&
                      validationErrMap.get('soprMgrCopCd')
                        ? validationErrMap.get('soprMgrCopCd')
                        : undefined
                    }
                  />
                </td>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('Vendor ID', 'Vendor ID')}
                  </GLabelAtom>
                </th>
                <td>
                  <ValidationBox msg={validationErrMap.get('cfrmVdcpCd')}>
                    <AlignBox style={{ alignItems: 'flex-start' }}>
                      <InputField
                        id="vdcpCd"
                        width="242px"
                        maxLength={12}
                        disabled={signUpFormData.cfrmVdcpCd}
                        value={signUpFormData.vdcpCd}
                        placeholder={t2('com.label.00016', '__Vendor ID를 입력하세요')}
                        status={
                          validationErrMap.get('vdcpCd') && signUpFormData.vdcpCd.trim() === ''
                            ? 'error'
                            : signUpFormData.cfrmVdcpCd == true
                            ? 'readonly'
                            : 'default'
                        }
                        helperText={
                          validationErrMap.get('vdcpCd') && signUpFormData.vdcpCd.trim() === ''
                            ? validationErrMap.get('vdcpCd')
                            : undefined
                        }
                        onChange={handleChangeVendorId}
                      />
                      <GButton
                        hasImg={false}
                        hasTxt={true}
                        txt={`Vendor ID ${t('com.label.00146', '__확인')}`}
                        sizes="medium"
                        btnstyled="outline"
                        btnColor="normal"
                        onClick={validateVenderId}
                      />
                      {signUpFormData.vdcpCtryCd &&
                        signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR') && (
                          <GButton
                            disabled={!signUpFormData.cfrmVdcpCd}
                            hasImg={false}
                            hasTxt={true}
                            txt={t('com.label.00011', '__공동인증서 등록')}
                            sizes="medium"
                            btnstyled="outline"
                            btnColor="normal"
                            style={{ position: 'relative' }}
                            onClick={(e) => {
                              if (!signUpFormData.cfrmVdcpCd) {
                                openCommonModal({
                                  modalType: 'alert',
                                  content: t(
                                    'com.label.00533',
                                    '__Vendor ID 확인 이후에 진행 가능합니다.'
                                  ),
                                });
                                return;
                              }
                              callCertModule({
                                userId: signUpFormData.vdcpCd,
                                bznoKey: signUpFormData.bznoKey,
                                callbackFn: (resultObject) => {
                                  setSignUpFormData((prev) => {
                                    return {
                                      ...prev,
                                      publCertKey: resultObject.RValue,
                                      dgsVal: resultObject.signedData,
                                    };
                                  });
                                },
                              });
                              return;
                            }}
                          />
                        )}
                    </AlignBox>
                  </ValidationBox>
                </td>
              </tr>
              <tr>
                <th style={{ display: 'none' }}>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00117', '__구분')}
                  </GLabelAtom>
                </th>
                <td style={{ display: 'none' }}>
                  <AlignBox>
                    <GridRadioVars2
                      id="dmstFrgDivsCd"
                      valueKey="cmnCd"
                      labelKey="cmnCdNm"
                      disabled={signUpFormData.cfrmVdcpCd || signUpFormData.soprMgrCopCd === 'C100'}
                      option={dmstFrgOptionList}
                      value={signUpFormData.dmstFrgDivsCd}
                      onChange={(e) => {
                        handleChangeFrgDivsCd(e);
                      }}
                    />
                  </AlignBox>
                </td>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00534', '__회원 가입자 이름')}
                  </GLabelAtom>
                </th>
                <td>
                  <InputField
                    id="vdcpMgrNm"
                    width="350px"
                    placeholder={t2('com.label.00013', '__이름을 입력하세요')}
                    status={
                      validationErrMap.get('vdcpMgrNm') && signUpFormData.vdcpMgrNm.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      validationErrMap.get('vdcpMgrNm') && signUpFormData.vdcpMgrNm.trim() === ''
                        ? validationErrMap.get('vdcpMgrNm')
                        : undefined
                    }
                    maxLength={25}
                    onChange={handleChangeForLoginForm}
                  />
                </td>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00017', '__구매처명')}
                  </GLabelAtom>
                </th>
                <td>
                  <InputField
                    id="vdcpNm"
                    width="350px"
                    placeholder={t2('com.label.00018', '__구매처명을 입력하세요')}
                    status={
                      validationErrMap.get('vdcpNm') && signUpFormData.vdcpNm.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      validationErrMap.get('vdcpNm') && signUpFormData.vdcpNm.trim() === ''
                        ? validationErrMap.get('vdcpNm')
                        : undefined
                    }
                    maxLength={100}
                    onChange={handleChangeForLoginForm}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00090', '__이메일')}
                  </GLabelAtom>
                </th>
                <td>
                  <ValidationBox msg={validationErrMap.get('vdcpMgrEmal')}>
                    <AlignBox>
                      <InputField
                        id="vdcpMgrEmalId"
                        width="165px"
                        status="default"
                        placeholder={t2('com.label.00025', '__이메일아이디')}
                        onChange={(e) => {
                          handleChangeForLoginForm(e);
                        }}
                        maxLength={100}
                      />
                      @
                      <InputField
                        id="vdcpMgrEmalDmn"
                        status="default"
                        placeholder={t2('com.label.00026', '__직접입력')}
                        width="165px"
                        onChange={(e) => {
                          handleChangeForLoginForm(e);
                        }}
                        maxLength={100}
                      />
                    </AlignBox>
                  </ValidationBox>
                </td>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00091', '__전화번호')}
                  </GLabelAtom>
                </th>
                <td>
                  <AlignBox>
                    <InputField
                      id="vdcpMgrPhn"
                      width="350px"
                      placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                      status={
                        validationErrMap.get('vdcpMgrPhn') &&
                        signUpFormData.vdcpMgrPhn.trim() === ''
                          ? 'error'
                          : 'default'
                      }
                      helperText={
                        validationErrMap.get('vdcpMgrPhn') &&
                        signUpFormData.vdcpMgrPhn.trim() === ''
                          ? validationErrMap.get('vdcpMgrPhn')
                          : undefined
                      }
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      maxLength={30}
                    />
                  </AlignBox>
                </td>
              </tr>
              {/* Vendor ID가 KR로 시작하면 패스워드 입력 받지 않음 */}
              {!signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR') && (
                <tr>
                  <th>
                    <GLabelAtom
                      align="left"
                      isRequired={
                        !signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR') ? true : false
                      }
                    >
                      {t('com.label.00535', '__패스워드')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="pwd"
                      width="350px"
                      placeholder={
                        !signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR')
                          ? t2('com.label.00536', '__패스워드를 입력하세요')
                          : ''
                      }
                      readOnly={
                        signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR') ? true : false
                      }
                      status={
                        signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR')
                          ? 'readonly'
                          : validationErrMap.get('pwd') && signUpFormData.pwd.trim() === ''
                          ? 'error'
                          : validationErrMap.get('pwdValid') &&
                            (!isValidPassword(signUpFormData.pwd) ||
                              signUpFormData.cfrmPwd.trim() !== signUpFormData.pwd)
                          ? 'error'
                          : validationErrMap.get('pwdLenOver')
                          ? 'error'
                          : 'default'
                      }
                      helperText={
                        signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR')
                          ? undefined
                          : validationErrMap.get('pwd') && signUpFormData.pwd.trim() === ''
                          ? validationErrMap.get('pwd')
                          : validationErrMap.get('pwdValid') &&
                            (!isValidPassword(signUpFormData.pwd) ||
                              signUpFormData.cfrmPwd.trim() !== signUpFormData.pwd)
                          ? validationErrMap.get('pwdValid')
                          : validationErrMap.get('pwdLenOver')
                          ? validationErrMap.get('pwdLenOver')
                          : undefined
                      }
                      isPassword={true}
                      maxLength={16}
                      onChange={handleChangeForLoginForm}
                    />
                  </td>
                  <th>
                    <GLabelAtom
                      align="left"
                      isRequired={
                        !signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR') ? true : false
                      }
                    >
                      {t('com.label.00020', '__패스워드 확인')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="cfrmPwd"
                      width="350px"
                      placeholder={
                        !signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR')
                          ? t2('com.label.00537', '__패스워드를 다시 입력하세요')
                          : ''
                      }
                      readOnly={
                        signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR') ? true : false
                      }
                      status={
                        signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR')
                          ? 'readonly'
                          : (validationErrMap.get('cfrmPwd') &&
                              signUpFormData.cfrmPwd.trim() === '') ||
                            (validationErrMap.get('cfrmPwdAll') &&
                              signUpFormData.cfrmPwd.trim() !== signUpFormData.pwd)
                          ? 'error'
                          : 'default'
                      }
                      helperText={
                        signUpFormData.vdcpCtryCd.toUpperCase().startsWith('KR')
                          ? undefined
                          : validationErrMap.get('cfrmPwd') && signUpFormData.cfrmPwd.trim() === ''
                          ? validationErrMap.get('cfrmPwd')
                          : validationErrMap.get('cfrmPwdAll') &&
                            signUpFormData.cfrmPwd.trim() !== signUpFormData.pwd
                          ? validationErrMap.get('cfrmPwdAll')
                          : undefined
                      }
                      isPassword={true}
                      maxLength={15}
                      onChange={handleChangeForLoginForm}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </GbasicTable>
        </GbasicTableWrap>

        <SubTitleBtnArea>
          <SubTitleBox
            content={t('com.label.00015', '__LG에너지솔루션 담당자')}
            showSubTitle={true}
            isVisibleComment={false}
            isVisibleInfo={false}
          />
        </SubTitleBtnArea>
        <GbasicTableWrap isBottomMgn={true}>
          <GbasicTable>
            <colgroup>
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00095', '__담당자')}
                  </GLabelAtom>
                </th>
                <td>
                  <ValidationBox msg={validationErrMap.get('soprMgrNm')}>
                    <VarsAutoInput
                      id="signup"
                      width="350px"
                      openModal={managerSrchModalOpen}
                      setOpenModal={setManagerSrchModalOpen}
                      signUpFormData={signUpFormData}
                      setSignUpFormData={setSignUpFormData}
                      selectedManagerData={selectedManagerData}
                      setSelectedManagerData={setSelectedManagerData}
                      onClick={() => setManagerSrchModalOpen(true)}
                      status={
                        validationErrMap.get('soprMgrNm') && signUpFormData.soprMgrNm.trim() === ''
                          ? 'error'
                          : 'default'
                      }
                    />
                  </ValidationBox>
                </td>
                <th>
                  <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
                </th>
                <td>
                  <AlignBox>
                    <InputField
                      disabled
                      readOnly={true}
                      width="350px"
                      status="disabled"
                      value={
                        selectedManagerData.length > 0 ? selectedManagerData[0].emlSvrDmnIfoNm : ''
                      }
                    />
                  </AlignBox>
                </td>
              </tr>
            </tbody>
          </GbasicTable>
        </GbasicTableWrap>
        <SubTitleBtnArea>
          <SubTitleBox
            content={t('com.label.00089', '__영업부서 담당자')}
            showSubTitle={true}
            isVisibleComment={false}
            isVisibleInfo={false}
          />
        </SubTitleBtnArea>
        <GbasicTableWrap isBottomMgn={true}>
          <GbasicTable>
            <colgroup>
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00012', '__이름')}
                  </GLabelAtom>
                </th>
                <td>
                  <InputField
                    id="vdcpMgrNm1"
                    width="350px"
                    placeholder={t2('com.label.00013', '__이름을 입력하세요')}
                    onChange={handleChangeForLoginForm}
                    status={
                      validationErrMap.get('vdcpMgrNm1') && signUpFormData.vdcpMgrNm1.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      validationErrMap.get('vdcpMgrNm1') && signUpFormData.vdcpMgrNm1.trim() === ''
                        ? validationErrMap.get('vdcpMgrNm1')
                        : undefined
                    }
                    maxLength={15}
                  />
                </td>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00091', '__전화번호')}
                  </GLabelAtom>
                </th>
                <td>
                  <AlignBox>
                    <InputField
                      id="vdcpMgrEncPhn"
                      width="350px"
                      placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                      status={
                        validationErrMap.get('vdcpMgrEncPhn') &&
                        signUpFormData.vdcpMgrEncPhn.trim() === ''
                          ? 'error'
                          : 'default'
                      }
                      helperText={
                        validationErrMap.get('vdcpMgrEncPhn') &&
                        signUpFormData.vdcpMgrEncPhn.trim() === ''
                          ? validationErrMap.get('vdcpMgrEncPhn')
                          : undefined
                      }
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      maxLength={100}
                    />
                  </AlignBox>
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00090', '__이메일')}
                  </GLabelAtom>
                </th>
                <td>
                  <ValidationBox msg={validationErrMap.get('vdcpMgrEmal1')}>
                    <AlignBox>
                      <InputField
                        id="vdcpMgrEmal1Id"
                        width="165px"
                        status="default"
                        placeholder={t2('com.label.00025', '__이메일아이디')}
                        onChange={(e) => {
                          handleChangeForLoginForm(e);
                        }}
                        maxLength={100}
                      />
                      @
                      <InputField
                        id="vdcpMgrEmal1Dmn"
                        status="default"
                        placeholder={t2('com.label.00026', '__직접입력')}
                        width="165px"
                        onChange={(e) => {
                          handleChangeForLoginForm(e);
                        }}
                        maxLength={100}
                      />
                    </AlignBox>
                  </ValidationBox>
                </td>
                <th>
                  <GLabelAtom align="left">{t('com.label.00665', '__팩스번호')}</GLabelAtom>
                </th>
                <td>
                  <AlignBox>
                    <InputField
                      id="vdcpMgrFaxNo"
                      width="350px"
                      placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                      status="default"
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      maxLength={15}
                    />
                  </AlignBox>
                </td>
                {/* <th>
                <GLabelAtom align="left">{t('com.label.00092', '__추가 이메일')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    id="vdcpMgrAddEmalId"
                    width="165px"
                    status="default"
                    placeholder={t2('com.label.00025', '__이메일아이디')}
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    maxLength={100}
                  />
                  @
                  <InputField
                    id="vdcpMgrAddEmalDmn"
                    status="default"
                    placeholder={t2('com.label.00026', '__직접입력')}
                    width="165px"
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    maxLength={100}
                  />
                </AlignBox>
              </td> */}
              </tr>
            </tbody>
          </GbasicTable>
        </GbasicTableWrap>

        <SubTitleBtnArea>
          <SubTitleBox
            content={t('com.label.00093', '__자금부서 담당자')}
            showSubTitle={true}
            isVisibleComment={false}
            isVisibleInfo={false}
          />
        </SubTitleBtnArea>
        <GbasicTableWrap isBottomMgn={true} style={{ marginBottom: '0px' }}>
          <GbasicTable>
            <colgroup>
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00012', '__이름')}
                  </GLabelAtom>
                </th>
                <td>
                  <InputField
                    id="vdcpFundMgrNm"
                    width="350px"
                    placeholder={t2('com.label.00013', '__이름을 입력하세요')}
                    onChange={handleChangeForLoginForm}
                    status={
                      validationErrMap.get('vdcpFundMgrNm') &&
                      signUpFormData.vdcpFundMgrNm.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      validationErrMap.get('vdcpFundMgrNm') &&
                      signUpFormData.vdcpFundMgrNm.trim() === ''
                        ? validationErrMap.get('vdcpFundMgrNm')
                        : undefined
                    }
                    maxLength={15}
                  />
                </td>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00091', '__전화번호')}
                  </GLabelAtom>
                </th>
                <td>
                  <AlignBox>
                    <InputField
                      id="vdcpFundMgrEncPhn"
                      width="350px"
                      placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                      status={
                        validationErrMap.get('vdcpFundMgrEncPhn') &&
                        signUpFormData.vdcpFundMgrEncPhn.trim() === ''
                          ? 'error'
                          : 'default'
                      }
                      helperText={
                        validationErrMap.get('vdcpFundMgrEncPhn') &&
                        signUpFormData.vdcpFundMgrEncPhn.trim() === ''
                          ? validationErrMap.get('vdcpFundMgrEncPhn')
                          : undefined
                      }
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      maxLength={100}
                    />
                  </AlignBox>
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t('com.label.00090', '__이메일')}
                  </GLabelAtom>
                </th>
                <td>
                  <ValidationBox msg={validationErrMap.get('vdcpFundMgrEmal')}>
                    <AlignBox>
                      <InputField
                        id="vdcpFundMgrEmalId"
                        width="165px"
                        status="default"
                        placeholder={t2('com.label.00025', '__이메일아이디')}
                        onChange={(e) => {
                          handleChangeForLoginForm(e);
                        }}
                        maxLength={100}
                      />
                      @
                      <InputField
                        id="vdcpFundMgrEmalDmn"
                        status="default"
                        placeholder={t2('com.label.00026', '__직접입력')}
                        width="165px"
                        onChange={(e) => {
                          handleChangeForLoginForm(e);
                        }}
                        maxLength={100}
                      />
                    </AlignBox>
                  </ValidationBox>
                </td>
                <th>
                  <GLabelAtom align="left">{t('com.label.00665', '__팩스번호')}</GLabelAtom>
                </th>
                <td>
                  <AlignBox>
                    <InputField
                      id="vdcpFundMgrFaxNo"
                      width="350px"
                      placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                      status="default"
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      maxLength={15}
                    />
                  </AlignBox>
                </td>
              </tr>
              {showRmk && (
                <tr>
                  <th>
                    <GLabelAtom align="left">{t('com.label.rmk', '__비고')}</GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="rmkCd"
                      width="350px"
                      placeholder={t2('com.label.rmkvali', '__비고를 입력하세요')}
                      onChange={handleChangeForLoginForm}
                      status="default"
                      maxLength={250}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </GbasicTable>
        </GbasicTableWrap>

        <ContentWrap>
          <ContentItem>
            <SubTitleBtnArea>
              <SubTitleBox
                content={t('com.label.00027', '__개인정보의 수집 및 이용에 대한 안내')}
                showSubTitle={true}
                isVisibleComment={false}
                isVisibleInfo={false}
              />
            </SubTitleBtnArea>
            {langCd === 'en' ? (
              <PrivacyAgreeEn />
            ) : langCd === 'ko' ? (
              <PrivacyAgreeKo />
            ) : (
              <PrivacyAgreeZhc />
            )}
            <RadioWrap>
              <ValidationBox msg={validationErrMap.get('idvlIfoClctCncCd')}>
                <GridRadioVars2
                  id="idvlIfoClctCncCd"
                  valueKey="cmnCd"
                  labelKey="cmnCdNm"
                  option={[
                    { cmnCd: 'Y', cmnCdNm: t('com.label.00030', '__동의') },
                    { cmnCd: 'N', cmnCdNm: t('com.label.00031', '__동의안함') },
                  ]}
                  value={signUpFormData.idvlIfoClctCncCd}
                  onChange={(e) => {
                    setSignUpFormData((prev) => {
                      return { ...prev, idvlIfoClctCncCd: e.target.value };
                    });
                  }}
                />
              </ValidationBox>
            </RadioWrap>
          </ContentItem>
          <ContentItem>
            <SubTitleBtnArea>
              <SubTitleBox
                content={t('com.label.00028', '__납품대금 지급에 관한 약정서')}
                showSubTitle={true}
                isVisibleComment={false}
                isVisibleInfo={false}
              />
            </SubTitleBtnArea>
            {langCd === 'en' ? (
              <DeliveryAgreeEn />
            ) : langCd === 'ko' ? (
              <DeliveryAgreeKo />
            ) : (
              <DeliveryAgreeZhc />
            )}
            <RadioWrap>
              <ValidationBox msg={validationErrMap.get('dlvPayAgmnCncCd')}>
                <GridRadioVars2
                  id="dlvPayAgmnCncCd"
                  valueKey="cmnCd"
                  labelKey="cmnCdNm"
                  option={[
                    { cmnCd: 'Y', cmnCdNm: t('com.label.00030', '__동의') },
                    { cmnCd: 'N', cmnCdNm: t('com.label.00031', '__동의안함') },
                  ]}
                  value={signUpFormData.dlvPayAgmnCncCd}
                  onChange={(e) => {
                    setSignUpFormData((prev) => {
                      return { ...prev, dlvPayAgmnCncCd: e.target.value };
                    });
                  }}
                />
              </ValidationBox>
            </RadioWrap>
          </ContentItem>

          {/* <ContentItem>
            <SubTitleBtnArea>
              <SubTitleBox
                content={t('com.label.00029', '고유식별정보의 수집 및 이용')}
                showSubTitle={true}
                isVisibleComment={false}
                isVisibleInfo={false}
              />
            </SubTitleBtnArea>
            <TextArea
              status="default"
              width="100%"
              flex={true}
              borderColor="#DDE0DF"
              readOnly
              value={`■ 고유식별정보 수집 항목
ο 주민등록번호, 외국인등록번호

■ 고유식별정보 수집 및 이용 목적
ο 관계법령에 따른 강사(비사업자)의 식별/확인 및 강사료 지급/세금신고

■ 고유식별정보 보유 및 이용 기간
전자상거래 등에서의 소비자보호에 관한 법률, 부가가치세법 등 관련 법령에 따라 강사료 지급 및 세금 신고를 위해, 강사료 지급 업무 종료 후, 5년 동안 보관합니다.
☞ 이용자는 고유식별정보의 수집 및 이용과 관련하여 동의를 거부할 권리가 있으며, 동의거부 시 회사는 공정하고 안정적인 거래를 진행할 수 없으므로 계약 체결 및 대금 지급에 제한이 있을 수 있습니다.
`}
            />

            <RadioWrap>
              <ValidationBox msg={validationErrMap.get('chtrIdnClctCncCd')}>
                <GridRadioVars2
                  id="chtrIdnClctCncCd"
                  valueKey="cmnCd"
                  labelKey="cmnCdNm"
                  option={[
                    { cmnCd: 'Y', cmnCdNm: t('com.label.00030', '__동의') },
                    { cmnCd: 'N', cmnCdNm: t('com.label.00031', '__동의안함') },
                  ]}
                  value={signUpFormData.chtrIdnClctCncCd}
                  onChange={(e) => {
                    setSignUpFormData((prev) => {
                      return { ...prev, chtrIdnClctCncCd: e.target.value };
                    });
                  }}
                />
              </ValidationBox>
            </RadioWrap>
          </ContentItem> */}
        </ContentWrap>
        <GButtonLayout>
          <GButtonBox style={{ marginBottom: '4px' }}>
            <GButton
              txt={t('com.label.00036', '취소')}
              sizes="medium"
              onClick={() => navigate('/')}
              btnstyled="outline"
              btnColor="normal"
              hasImg={false}
              hasTxt={true}
            />
            <GButton
              chkImg={Save}
              txt={t('com.label.00032', '__가입')}
              sizes="medium"
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
              onClick={handleClickSignUp}
            />
          </GButtonBox>
        </GButtonLayout>
      </Scrollable>
    </ScreenBox>
  );
};

const ScreenBox = styled.div`
  width: 100%;
  height: 850px;
  padding-right: -16px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 너비 */
    height: 6px;
    background: white; /*스크롤바 뒷 배경 색상*/
  }

  &::-webkit-scrollbar-thumb {
    height: 10%; /* 스크롤바의 길이 */
    background: #8d8d8d; /* 스크롤바의 색상 */
    border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }
`;

const Scrollable = styled.div`
  padding-right: 32px;
`;

const ContentWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 275px;
`;

const ContentItem = styled(ContentWrap)`
  margin-top: 12px;
`;

export default MemberRegistPage;
