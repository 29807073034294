/** @jsxImportSource @emotion/react */
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material';
import Router from 'routers/Router';
import { useLocation, useNavigate } from 'react-router-dom';
import HeadMenuBar from 'components/layouts/menu/HeadMenuBar';
import { CommonModal } from 'components/modals/common/CommonModal';
import { MessageModal } from 'components/modals/common/MessageModal';
import { getSession } from 'apis/Session';
import useSessionStore from 'stores/useSessionStore';
import useLanguageStore from 'stores/useLanguageStore';
import { createContext, useEffect, useState, useMemo } from 'react';
import { MainLayout } from 'components/layouts/MainLayout';
import { HomeMenu, Menu, MenuContextType, defaultMenuContext } from 'models/admin/Menu';
import { createMenuAccessLog } from 'apis/admin/Log';
import { Loading } from 'components/process/Loading';
import { MenuAtchFileModal } from 'components/modals/common/MenuAtchFileModal';
import { MessageBar } from 'components/process/MessageBar';
import { getAccessInfo } from 'apis/vars/common/Common';
import useAuthStore from 'stores/useAuthStore';
import { AccessInfo, CommonAccessCode } from 'models/vars/common/Common';
import styled from '@emotion/styled';

export const MenuContext = createContext<MenuContextType>(defaultMenuContext);

export const ColorModeContext = createContext({
  toggleColorMode: () => {
    return;
  },
});

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { userId, setSession, menus, accessLevelInfo } = useSessionStore();
  const { accessCode, setAccessCode, accessToken } = useAuthStore();
  const { changeLanguage, initLanguage } = useLanguageStore();
  const [contextValue, setContextValue] = useState<MenuContextType>({
    ...defaultMenuContext,
    handleMenuChange: (item) =>
      setContextValue((prev) => {
        return { ...prev, ...item };
      }),
  });
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const LogMenuAccess = () => {
    const splitedPath = pathname.split('/');
    const menuId = splitedPath.length > 1 ? splitedPath[splitedPath.length - 1] : '';
    createMenuAccessLog(menuId);
  };

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    (async () => {
      const appEnv = `${process.env.REACT_APP_NODE_ENV}`; //local, dev, prd

      const response = await getAccessInfo('en_US'); //Intl.DateTimeFormat().resolvedOptions().locale, navigator.language
      if (response.data) {
        const accessInfo = response.data;
        const accessCode = accessInfo.accessCode;
        setAccessCode(accessCode);
        changeLanguage(accessInfo.langCd || 'en');

        // setCurrentMenu(session.menus);
        const loginUrl = '/'; // /dev/login

        // 외부
        if (accessCode == CommonAccessCode.EXTERNAL) {
          navigate(loginUrl, { replace: true });
          // 내부
        } else if (accessCode == CommonAccessCode.INTERNAL) {
          if (appEnv == 'local') {
            //방어코드 (로컬에서 내부 테스트 위함)
            if (pathname == '/dev/login') {
              navigate('/dev/login', { replace: true });
            } else if (pathname == '/business/login') {
              navigate('/business/login', { replace: true });
            } else {
              navigate(loginUrl, { replace: true });
            }
          } else {
            // 개발, 운영
            if (!userId) {
              // 사용자 정보가 없는 경우
              if (appEnv == 'dev') {
                //24.04.11 테스트
                if (pathname == '/dev/login') {
                  const response = await getSession();
                  if (response && response.successOrNot === 'Y' && response?.data) {
                    // 세션 있음
                    const session = response.data;
                    setSession(session);
                    changeLanguage(session.langCd || 'en');
                    // if (pathname !== '/') {
                    //   LogMenuAccess();
                    // }
                    // 시스템 관리자 화면 이동
                    navigate('/system/admin/member/member-list', { replace: true });
                  } else {
                    // 세션 없음 sso 연동
                    const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
                    window.location.href =
                      'http://gssodev.lgensol.com:8001/nls3/cookieLogin.jsp?UURL=' +
                      //`${process.env.REACT_APP_API_BASE_URL}` +
                      `http://varsdev.lgensol.com/dev/login` +
                      '&RTOA=1';
                  }
                } else if (pathname == '/business/login') {
                  navigate('/business/login', { replace: true });
                }
              } else {
                // 운영 사용자 정보 없는 경우
                if (pathname == '/business/login') {
                  // '/business/login'으로 진입
                  navigate('/business/login', { replace: true });
                } else {
                  const response = await getSession();

                  if (response && response.successOrNot === 'Y' && response?.data) {
                    // 세션 있음
                    const session = response.data;

                    setSession(session);

                    changeLanguage(session.langCd || 'en');

                    if (pathname !== '/') {
                      LogMenuAccess();
                    }
                    // 시스템 관리자 화면 이동
                    navigate('/system/admin/member/member-list', { replace: true });
                  } else {
                    // 세션 없음 sso 연동
                    // const appEnv = `${process.env.REACT_APP_NODE_ENV}`; 미사용
                    window.location.href =
                      'http://gsso.lgensol.com:8001/nls3/cookieLogin.jsp?UURL=' +
                      'http://vars.lgensol.com' +
                      '&RTOA=1';
                  }
                }
              }
            } else {
              // 개발, 운영의 사용자 정보 있음. 내부이므로 관리자 화면 이동, '/business/login' 으로 진입한 경우엔 로그인 화면으로
              if (pathname == '/business/login') {
                navigate('/business/login', { replace: true });
              } else {
                navigate('/system/admin/member/member-list', { replace: true });
              }
            }
          }
          // 로컬 개발환경
        } else if (accessCode == CommonAccessCode.LOCAL) {
          if (pathname == '/dev/login') {
            navigate('/dev/login', { replace: true });
          } else if (pathname == '/business/login') {
            navigate('/business/login', { replace: true });
          } else {
            navigate(loginUrl, { replace: true });
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (menus?.length > 0) {
      const currentUrl = location.pathname.substring(1);
      if (currentUrl === '') {
        setContextValue({
          ...contextValue,
          currentMenu: HomeMenu,
          clickedByHeaderMenu: false,
          selectedHeaderMenu: '',
          currentPageName: 'Home',
          currentPage: HomeMenu,
        });
        return;
      }
    } else {
      if (location.pathname == '/member/regist') {
        setContextValue({
          ...contextValue,
          currentMenu: HomeMenu,
          clickedByHeaderMenu: true,
          selectedHeaderMenu: '',
          currentPageName: '회원가입',
          currentPage: {
            mnuId: '000147',
            mnuNm: '회원가입',
            mnuUrl: 'member/regist',
            msgCtn: 'com.label.00005',
          },
        });
      }
    }
    setCurrentMenu(menus);
  }, [pathname, menus]);

  const setCurrentMenu = (sessionMenus) => {
    if (sessionMenus) {
      const currentUrl = location.pathname.substring(1);
      const currentPage = sessionMenus.find((menu) => menu.mnuUrl === currentUrl);
      if (currentPage) {
        // if (contextValue.currentMenu.mnuId && contextValue.currentMenu.mnuId !== '') {
        let menu;
        if (currentPage.mnuEpsYn === 'N') {
          menu = sessionMenus.find((menu) => currentPage.upprMnuId === menu.mnuId);
        } else {
          menu = sessionMenus.find((menu) => currentPage.mnuId === menu.mnuId);
        }
        setContextValue((contextState) => {
          return {
            ...contextState,
            currentPage: currentPage,
            currentPageName: currentPage.mnuNm,
            currentMenu: menu,
            clickedByHeaderMenu: false,
          };
        });
      }
    }
  };

  useEffect(() => {
    if (pathname !== '/' && userId) {
      if (!accessToken) {
        LogMenuAccess();
      }
    }
    if (pathname == '/dev/login') {
      setShowMenu(() => {
        return false;
      });
    } else {
      setShowMenu(() => {
        return true;
      });
    }
  }, [pathname]);

  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  // const themeFlag = mode == 'dark' ? darkTheme : defaultTheme;
  const theme = createTheme({
    typography: {
      fontFamily:
        "'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD', 'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif",
    },
  });

  return (
    <>
      {/* {(userId || pathname === '/dev/login') && ( */}
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <MenuContext.Provider value={contextValue}>
            <Layout>
              <HeadMenuBar />
              <MainLayout showMenu={showMenu}>
                <Router />
              </MainLayout>
            </Layout>
            <>
              <CommonModal />
              <MessageModal />
              <MenuAtchFileModal />
              <Loading />
              <MessageBar />
            </>
          </MenuContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
      {/* )} */}
    </>
  );
}
const Layout = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1366px;
  display: flex;
  flex-direction: column;
`;

export default App;
